import './MeetingList.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { MeetingItem } from './MeetingItem';
import { Meeting } from '../../store/model/Meeting';
import UserInfo from '../../store/UserInfo';
import { NotificationFirebaseData } from '../../api/MeetingFirebaseApi';
import MeetingTaggedSearchInput from './MeetingTaggedSearchInput';

const enum TableColDirection {
  none = 'none',
  up = 'up',
  down = 'down',
}

const enum TableColName {
  status = 'status',
  title = 'title',
  startTime = 'startTime',
}

const tableColDirectionConfigs: {[key in TableColDirection]: {icon: string}} = {
  [TableColDirection.none]: {icon: 'bi-filter'},
  [TableColDirection.up]: {icon: 'bi-sort-up'},
  [TableColDirection.down]: {icon: 'bi-sort-down'},
}

const getSortField = (colName: TableColName): string => {
  switch (colName) {
    case TableColName.title:
      return 'title';
    case TableColName.startTime:
      return 'startTime';
    case TableColName.status:
    default:
      return 'state';
  }
};

export enum DisplayLayout {
  list = 'list',
  grid = 'grid',
}

const displayLayoutConfigs: {[key in DisplayLayout]: {name: string, icon: string}} = {
  [DisplayLayout.list]: {name: 'リストレイアウト', icon: 'bi-list-ul'},
  [DisplayLayout.grid]: {name: 'グリッドレイアウト', icon: 'bi-grid'},
}

// ローカルストレージからレイアウト設定を取得
let storedLayout = DisplayLayout.grid;
try {
  storedLayout = (localStorage.getItem('layout') ?? storedLayout) as DisplayLayout;
} catch (e) {
  console.error(e);
}

export const MeetingList = observer(({notifications, meetings}: {notifications: NotificationFirebaseData[], meetings: Meeting[]}) => {
  const { t, i18n } = useTranslation()
  const [searching, setSearching] = useState<boolean>(true);
  const [displayMeetings, setDisplayMeetings] = useState<Meeting[]>([]);
  const [displayLayout, setDisplayLayout] = useState<DisplayLayout>(storedLayout);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  const [sort, setSort] = useState<{
    name: TableColName,
    direction: TableColDirection,
    display: string
  }>({
    name: TableColName.status,
    direction: TableColDirection.up,
    display: 'ステータス'
  });

  const handleSortClick = useCallback((name: TableColName, direction: TableColDirection = TableColDirection.up) => {
    if (sort.name === name && sort.direction === TableColDirection.up) {
      direction = TableColDirection.down;
    }
    let display = 'ステータス';
    switch (name) {
      case TableColName.title:
        display = 'タイトル';
        break;
      case TableColName.startTime:
        display = '開始日時';
        break;
    }
    setSort({name, direction, display});
  }, [sort.name, sort.direction]);

  useEffect(() => {
    localStorage.setItem('layout', displayLayout);
  }, [displayLayout]);

  const filteredNotifications = useMemo(() => {
    const notificationMap = new Map<string, NotificationFirebaseData[]>();
    notifications.forEach((n) => {
      if (n.meeting) { // undefined をスキップ
        if (!notificationMap.has(n.meeting)) {
          notificationMap.set(n.meeting, []);
        }
        notificationMap.get(n.meeting)?.push(n);
      }
    });
    return notificationMap;
  }, [notifications]);

  const renderedMeetingItems = displayMeetings.map((meeting) => (
    <MeetingItem
      key={meeting.id}
      meeting={meeting}
      notifications={filteredNotifications.get(meeting.id) || []}
      allTags={allTags}
      keywords={keywords}
      layout={displayLayout}
    />
  ));

  return (
    <div className='meeting-list container p-4 p-md-4 p-lg-5'>
      <div className="meeting-list-header d-flex flex-wrap">
        <img src="/donutai_agent_1_0_logo.png" style={{ width: '270px', maxHeight: '75px', paddingBottom: '10px', marginRight: '20px' }} alt="Donut AI" />
        <div className="flex-grow-1 align-self-center">
          <div className="d-flex flex-wrap gap-3">
            <div className="flex-grow-1 align-self-center">
              {UserInfo.meetings?.loaded && (<MeetingTaggedSearchInput
                meetings={meetings}
                onSearching={setSearching}
                onFilteredMeetingsChange={setDisplayMeetings}
                onKeywordsChange={setKeywords}
                onTagsChange={setAllTags}
                sortField={getSortField(sort.name)}
                sortDirection={sort.direction === TableColDirection.up ? 'asc' : 'desc'}
                placeholder={t('検索キーワードを入力...')}
              />)}
            </div>
            <div className="align-self-center w-100 w-md-auto">
              <div className="float-end">
                <div className="btn-group" role="group">
                  {UserInfo.meetings?.loaded && Object.values(DisplayLayout).map((layout, index) => (
                    <React.Fragment key={layout}>
                      <input
                        id={`displayLayout${index}`}
                        type="radio"
                        className="btn-check btn-display-layout"
                        name="displayLayout"
                        title={displayLayoutConfigs[layout].name}
                        value={DisplayLayout[layout]}
                        checked={displayLayout === DisplayLayout[layout]}
                        onChange={() => {}} // Prevent warnings from occurring in your development environment
                        onClick={(e) => setDisplayLayout(layout)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor={`displayLayout${index}`}>
                        <i className={`bi ${displayLayoutConfigs[layout].icon}`}></i>
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`meeting-items position-relative ${displayLayout === DisplayLayout.grid ? 'meeting-items-grid' : 'meeting-items-list'}`}>
        {UserInfo.meetings?.loaded ? (
          UserInfo.meetings.dataArray.length ? (
            displayMeetings.length ? (
              displayLayout === DisplayLayout.grid ? (
                <>
                  <div className="float-end">
                    <div className="btn-group" role="group">
                      <button
                        title={t('並べ替え')}
                        type="button"
                        className="btn btn-outline-primary dropdown-toggle"
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        data-bs-toggle="dropdown">
                        {t(sort.display)}
                      </button>
                      <ul className="dropdown-menu">
                        <li><h6 className="dropdown-header">{t('並べ替え')}</h6></li>
                        <li>
                          <hr className="dropdown-divider" style={{ marginTop: 0 }} />
                        </li>
                        <li>
                          <button className="dropdown-item"
                                  onClick={() => handleSortClick(TableColName.status, sort.direction)}>{t('ステータス')}</button>
                        </li>
                        <li>
                          <button className="dropdown-item"
                                  onClick={() => handleSortClick(TableColName.title, sort.direction)}>{t('タイトル')}</button>
                        </li>
                        <li>
                          <button className="dropdown-item"
                                  onClick={() => handleSortClick(TableColName.startTime, sort.direction)}>{t('開始日時')}</button>
                        </li>
                      </ul>
                      <button type="button" className="btn btn-outline-success"
                              onClick={() => handleSortClick(sort.name)}>
                        <i className={`bi ${tableColDirectionConfigs[sort.direction].icon}`}></i>
                      </button>
                    </div>
                  </div>
                  <div className={"row w-100 pt-3"}>
                    {renderedMeetingItems}
                  </div>
                </>
              ) : (
                <div className="meeting-items-list table-responsive mt-5">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th style={{ minWidth: '6em', textAlign: 'center' }}>
                        {t('状態')} <span onClick={(e) => handleSortClick(TableColName.status)}
                                          className={`sort ${sort.name === TableColName.status ? 'sorted' : ''}`}>{sort.name === TableColName.status ?
                        <i className={`bi ${tableColDirectionConfigs[sort.direction].icon}`}></i> :
                        <i className={tableColDirectionConfigs.none.icon}></i>}</span>
                      </th>
                      <th style={{ minWidth: '15em' }}>
                        {t('タイトル')} <span onClick={(e) => handleSortClick(TableColName.title)}
                                              className={`sort ${sort.name === TableColName.title ? 'sorted' : ''}`}>{sort.name === TableColName.title ?
                        <i className={`bi ${tableColDirectionConfigs[sort.direction].icon}`}></i> :
                        <i className={tableColDirectionConfigs.none.icon}></i>}</span>
                      </th>
                      <th style={{ minWidth: '10em' }}>
                        {t('開始日時')} <span onClick={(e) => handleSortClick(TableColName.startTime)}
                                              className={`sort ${sort.name === TableColName.startTime ? 'sorted' : ''}`}>{sort.name === TableColName.startTime ?
                        <i className={`bi ${tableColDirectionConfigs[sort.direction].icon}`}></i> :
                        <i className={tableColDirectionConfigs.none.icon}></i>}</span>
                      </th>
                      <th style={{ minWidth: i18n.language === 'en' ? '9em' : i18n.language === 'id' ? '7em' : '5em' }}>
                        {t('人数')}
                      </th>
                      <th style={{ minWidth: '10em' }}>
                        {t('会議時間')}
                      </th>
                      <th style={{ minWidth: '10em' }}>
                        {t('要約')}
                      </th>
                      <th style={{ minWidth: '3em' }}>
                        {t('操作')}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderedMeetingItems}
                    </tbody>
                  </table>
                </div>
              )
            ) : searching ? (
              <div className="row py-5">
                <div className="col text-center">{t('処理中…')}</div>
              </div>
            ) : (
              <div className="row py-5">
                <div className="col text-secondary text-center">{t('ミーティングがありません。')}</div>
              </div>
            )
          ) : (
            <div className="row py-5">
              <div className="col text-secondary text-center">{t('ミーティングがありません。')}</div>
            </div>
          )
        ) : (
          <div className="row py-5">
            <div className="col text-center">{t('処理中…')}</div>
          </div>
        )}
      </div>
    </div>
  )
})
