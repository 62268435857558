import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getSharedInfo } from '../../api/MeetingApi';
import { ChatMessage, Meeting, timestampToDate } from '../../store/model/Meeting';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from '../../view/LoadingOverlay';
import { openSummaryProcessView } from '../MeetingList/MeetingItem';
import { MeetingList } from '../MeetingList/MeetingList';
import { NotificationFirebaseData } from '../../api/MeetingFirebaseApi';

export const Share = observer(({notifications, meetings}: {notifications: NotificationFirebaseData[], meetings: Meeting[]}) => {
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isEffectedRef = useRef<boolean>(false);
  const tokenRef = useRef<string>(token);

  useEffect(() => {
    (async() => {
      try {
        if (isEffectedRef.current || !tokenRef.current || tokenRef.current === '') {
          return;
        }
        isEffectedRef.current = true;
        const response = await getSharedInfo(tokenRef.current);
        //console.log(response)
        if (!response.data.success || !response.data.meeting) {
          alert(t(response.data.message ?? 'エラーが発生しました'));
        } else {
          response.data.meeting.createdAt.seconds = response.data.meeting.createdAt._seconds;
          response.data.meeting.updatedAt.seconds = response.data.meeting.updatedAt._seconds;
          const classMeeting = new Meeting(response.data.meeting._id, response.data.meeting);
          classMeeting.messages = response.data.meeting.messages.map((m: ChatMessage) => {
            if (m.createdAt) {
              m.createdAt = timestampToDate(m.createdAt)
            }
            if (m.updatedAt) {
              m.updatedAt = timestampToDate(m.updatedAt)
            }
            return {...m}
          })
          openSummaryProcessView({meeting: classMeeting, isShare: !response.data.isOwner});
        }
      } catch (e) {
        alert(t('エラーが発生しました'));
        console.error(e);
      }
      setIsLoaded(true);
    })();
  }, [isLoaded, t]);

  return (
    <>
      <LoadingOverlay active={!isLoaded} />
      <MeetingList notifications={notifications} meetings={meetings} />
    </>
  )
});
