import i18next from "i18next";
import EventDispatcher, { type ObjectChangeListener } from '../util/EventDispatcher'
import { type PopupOptions } from './Popup'
import React from 'react';
import { createRoot } from 'react-dom/client';
import Popup from './Popup';

type ModalInstance = {
  id: number;
  options: PopupOptions;
  resolve: (value: boolean) => void;
  reject: (reason?: any) => void;
  container: HTMLDivElement;
  root: any;
  isClosing: boolean;
  closePromise?: Promise<void>;
};

export const BUTTON_MODES = {
  OK_CANCEL: 'okCancel',
  OK: 'ok',
  CANCEL: 'cancel',
  NONE: 'none',
}

export const SIZE_MODE = {
  XLARGE: 'modal-xl',
  LARGE: 'modal-lg',
  MEDIUM: '',
  SMALL: 'modal-sm',
}

export const ACTIONS = {
  SHOW: 'show',
  HIDE: 'hide',
  MANUAL_UPDATE: 'manualUpdate',
}

export const POPUP_DEFAULT: PopupOptions = {
  title: '',
  titleRight: '',
  content: '',
  btnMode: BUTTON_MODES.OK_CANCEL,
  contentReady: undefined,
  resolve: () => { },
  reject: () => { },
  show: false,
  okText: 'OK',
  cancelText: 'cancel',
  okAction: undefined,
  size: SIZE_MODE.MEDIUM,
  staticBackdrop: true,
  center: true,
  closeButton: true,
}

export const BASE_ZINDEX = 1050;

const POPUP_CHANNEL = 'popup'

const dispatcher = new EventDispatcher()

class ModalManager {
  private static modals: ModalInstance[] = [];
  private static currentId = 0;

  private static addGlobalStyles() {
    const styleId = 'popup-manager-styles';
    if (!document.getElementById(styleId)) {
      const style = document.createElement('style');
      style.id = styleId;
      document.head.appendChild(style);
    }
    return document.getElementById(styleId) as HTMLStyleElement;
  }

  private static updateStyles() {
    const style = this.addGlobalStyles();

    // モーダルとbackdropのz-indexを交互に設定
    const rules = this.modals.map((modal, index) => {
      const modalZIndex = BASE_ZINDEX + (index * 20);  // モーダルは20ずつ増加
      const backdropZIndex = modalZIndex - 10;         // backdropはモーダルの10上

      return `
        div:has(#modal-${modal.id}){
          z-index: ${modalZIndex} !important;
        }
        .modal-backdrop-${modal.id} {
          z-index: ${backdropZIndex} !important;
        }
      `;
    }).join('\n');

    style.textContent = rules;
  }

  static show(options: PopupOptions): Promise<boolean> {
    const container = document.createElement('div');
    document.body.appendChild(container);
    const modalId = ++this.currentId;

    return new Promise<boolean>((resolve, reject) => {
      const modalRoot = createRoot(container);

      const modal: ModalInstance = {
        id: modalId,
        options,
        resolve,
        reject,
        container,
        root: modalRoot,
        isClosing: false
      };

      this.modals.push(modal);
      this.updateStyles();

      const handleResolve = async (result: boolean) => {
        if (modal.isClosing || modal.closePromise) {
          return;
        }

        modal.isClosing = true;
        modal.closePromise = (async () => {
          try {
            await this.closeModal(modal.id, result);
          } finally {
            modal.isClosing = false;
            modal.closePromise = undefined;
          }
        })();

        await modal.closePromise;
      };

      modalRoot.render(
        <Popup
          {...options}
          modalId={modalId}
          show={true}
          resolve={handleResolve}
        />
      );
    });
  }

  private static async closeModal(modalId: number, result: boolean) {
    const modalIndex = this.modals.findIndex(m => m.id === modalId);
    if (modalIndex === -1) return;

    const modal = this.modals[modalIndex];

    // Allow any pending state updates to complete
    await Promise.resolve();

    try {
      modal.root.unmount();
      modal.container.remove();
      modal.resolve(result);
      this.modals.splice(modalIndex, 1);

      this.updateStyles();

      // Re-render remaining modals
      await Promise.all(this.modals.map(m => {
        if (m.isClosing) return Promise.resolve();
        return new Promise<void>(resolve => {
          m.root.render(
            <Popup
              {...m.options}
              modalId={m.id}
              show={true}
              resolve={(result: boolean) => {
                this.closeModal(m.id, result).finally(resolve);
              }}
            />
          );
        });
      }));
    } catch (error) {
      console.error('Error closing modal:', error);
      modal.reject(error);
    }
  }

  static closeLatest(result = false) {
    const modal = this.modals[this.modals.length - 1];
    if (modal && !modal.isClosing) {
      this.closeModal(modal.id, result);
    }
  }

  static closeAll() {
    [...this.modals].reverse().forEach(modal => {
      if (!modal.isClosing) {
        this.closeModal(modal.id, false);
      }
    });
  }

  static updateLatest(state: Partial<PopupOptions>) {
    const currentModal = this.modals[this.modals.length - 1];
    if (currentModal && !currentModal.isClosing) {
      const updatedOptions = {
        ...currentModal.options,
        ...state,
      };
      currentModal.options = updatedOptions;
      currentModal.root.render(
        <Popup
          {...updatedOptions}
          modalId={currentModal.id}
          show={true}
          resolve={(result: boolean) => this.closeModal(currentModal.id, result)}
        />
      );
    }
  }
}

export const show = (options: PopupOptions) => {
  const finalOptions = {
    ...POPUP_DEFAULT,
    ...options,
    cancelText: i18next.t('キャンセル'),
  };

  return ModalManager.show(finalOptions);
};

export const hide = (result = false) => {
  ModalManager.closeLatest(result);
};

export const hideAll = () => {
  ModalManager.closeAll();
};

export const updateState = (state: Partial<PopupOptions>) => {
  ModalManager.updateLatest(state);
};

export const addPopupListener = (listener: ObjectChangeListener<never, PopupOptions, any>) => {
  return dispatcher.addListener(listener, POPUP_CHANNEL);
};
