import React, { Suspense, useCallback, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BUTTON_MODES } from './PopupEvent';

export type PopupOptions = {
  title?: React.ReactNode;
  titleRight?: React.ReactNode;
  content?: React.ReactNode;
  btnMode?: string;
  contentReady?: boolean | Promise<boolean>;
  resolve?: (result: any) => void;
  reject?: (error: any) => void;
  show?: boolean;
  okText?: string;
  cancelText?: string;
  okAction?: (result: any) => Promise<any> | any;
  size?: string;
  staticBackdrop?: boolean;
  center?: boolean;
  zIndex?: number;
  modalId?: number;
  closeButton?: boolean;
}

function Popup(props: PopupOptions) {
  const [loading, setLoading] = useState(false);
  const [contentReady, setContentReady] = useState<boolean>(
    !(props.contentReady instanceof Promise)
  );

  React.useEffect(() => {
    if (props.contentReady instanceof Promise) {
      props.contentReady.then(() => {
        setContentReady(true);
      });
    }
  }, [props.contentReady]);

  const handleClose = useCallback(() => {
    if (props.resolve) {
      props.resolve(false);
    }
  }, [props]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      let result = true;
      if (props.okAction) {
        result = await props.okAction(result);
      }
      if (props.resolve) {
        props.resolve(result);
      }
    } catch (error) {
      console.error('Error in modal confirmation:', error);
      if (props.reject) {
        props.reject(error);
      }
    } finally {
      setLoading(false);
    }
  }, [props]);

  return (
    <Modal
      id={`modal-${props.modalId}`}
      show={props.show}
      onHide={handleClose}
      backdropClassName={`modal-backdrop-${props.modalId}`}
      backdrop={props.staticBackdrop ? 'static' : true}
      keyboard={!props.staticBackdrop}
      centered={props.center}
      size={props.size === 'modal-xl' ? 'xl' :
        props.size === 'modal-lg' ? 'lg' :
          props.size === 'modal-sm' ? 'sm' : undefined}
    >
      {props.title && (
        <Modal.Header closeButton={props.closeButton}>
          <Modal.Title>{props.title}</Modal.Title>
          {props.titleRight && props.titleRight !== '' && (<div className="modal-title-right">{props.titleRight}</div>)}
        </Modal.Header>
      )}
      <Modal.Body>
        <Suspense fallback={(
          <div className="container">
            <div className="row justify-content-center my-3">
              <div className="col-auto">
                <div className="spinner-border color-focus" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        )}>
          {props.content}
        </Suspense>
      </Modal.Body>
      {props.btnMode !== BUTTON_MODES.NONE && (
        <Modal.Footer className={
          (props.btnMode === BUTTON_MODES.OK || props.btnMode === BUTTON_MODES.CANCEL)
            ? 'justify-content-center'
            : ''
        }>
          {props.btnMode !== BUTTON_MODES.OK && (
            <Button
              variant="secondary"
              onClick={handleClose}
            >
              {props.cancelText}
            </Button>
          )}
          {props.btnMode !== BUTTON_MODES.CANCEL && (
            <Button
              variant="primary"
              onClick={handleConfirm}
              disabled={loading || !contentReady}
            >
              {props.okText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default Popup;
