import 'firebaseui/dist/firebaseui.css'
import './LoginPage.css'

import { type ChangeEventHandler, type MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth'

import { useCountDown } from '../../hook/useCountDown'
import UserInfo, { type UserRegistrationData, type TPlan } from '../../store/UserInfo'
import Api from '../../api/Api'
import { GET_RESELLER_INFO, GET_RESELLER_PLANS, LIST_OF_AVAILABLE_PLANS } from '../../constant/Endpoints'
import { PlanSelection } from './PlanSelection'
import { sentryLog } from "../../util/sentry";
import { _parseQueryParam } from '../../util/Util'
import { SIGN_IN_MODE } from './constants'
import { CompanyFields } from './CompanyFields'
import { EmbeddedTos } from './EmbeddedTos'
import { Auth } from '../../firebase'
import { BUTTON_MODES, show } from '../../view/PopupEvent'
import { PasswordInput } from './PasswordInput'

type GPTechReseller = {
  id: string
  code: string
  name: string
}

const isAcceptablePassword = (pwd: string) => {
  return !!pwd.trim()
}

const handlePopupSignIn = async (provider: any, name: string) => {
  try {
    await signInWithPopup(Auth, provider)
  } catch (error: any) {
    if (error.code === 'auth/popup-closed-by-user') {
      // user closed the popup, no action needed
      return
    } else {
      show({
        title: `${name}ログインエラー`,
        content: `${name}ログインに失敗しました。しばらくしてから再度お試しください。`,
        btnMode: BUTTON_MODES.OK,
      })
      console.error(`Error during ${name} sign in: `, error);
    }
  }
}

type CustomLoginButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  title: string
  shortTitle: string
  iconUrl: string
}
const CustomLoginButton = ({ onClick, title, shortTitle, iconUrl }: CustomLoginButtonProps) => {
  return (
    <button className='firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button mt-3 bg-white'
      type='button' data-provider-id='google.com' data-upgraded=',MaterialButton' onClick={onClick}>
      <span className='firebaseui-idp-icon-wrapper'>
        <img className='firebaseui-idp-icon' alt={title} src={iconUrl} />
      </span>
      <span className='firebaseui-idp-text firebaseui-idp-text-long'>{title}</span>
      <span className='firebaseui-idp-text firebaseui-idp-text-short'>{shortTitle}</span>
    </button>
  )
}

const LoginPage = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.language

  const SIGN_IN_ERROR = useMemo(() => ({
    WEAK_PASSWORD: t('より強いパスワードが必要'), //'パスワードは６桁以上で、大文字、小文字、数字を含む必要があります',
    EMPTY: t('メールアドレスとパスワードの両方を入力してください'),
    EMPTY_NAME: t('名前が必要です'),
    EMPTY_DISPLAY_NAME: t('お名前が必要です'),
    UNKNOWN: t('不明なエラー'),
    // NOTE: language here is required to react to language change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [t, language])

  // for personal account
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const [isCompany, setIsCompany] = useState(true)

  // for company account
  const nameRef = useRef<HTMLInputElement>(null)
  const nameKanaRef = useRef<HTMLInputElement>(null)
  const companyRef = useRef<HTMLInputElement>(null)
  const companyKanaRef = useRef<HTMLInputElement>(null)
  const deptRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const addrRef = useRef<HTMLInputElement>(null)
  const resellerCodeRef = useRef<HTMLInputElement>(null)
  const resellerNameRef = useRef<HTMLInputElement>(null)
  const userCodeRef = useRef<HTMLInputElement>(null)

  // TOS agreement
  const [tosAgreed, setTosAgreed] = useState(false)

  const handleIsCompanyChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setIsCompany(e.target.checked)
  }, [])

  const [planOptions, setPlanOptions] = useState<Array<TPlan>>([])
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>()
  const [regInfo, setRegInfo] = useState<UserRegistrationData>({
    email: '',
    password: '',
  })

  const [mode, setMode] = useState(SIGN_IN_MODE.SIGN_IN)
  const [loading, setLoading] = useState(false)
  const [signInError, setSignInError] = useState('')

  const [countDown, startCountDown, countDownRef] = useCountDown(60, 'emailReset')

  // support for reseller dedicated user registration page
  const [reseller, setReseller] = useState<GPTechReseller | null>()
  const [sellerPlans, setSellerPlans] = useState<TPlan[]>()
  useEffect(() => {
    // only load reseller info once
    if (reseller !== undefined) {
      return
    }

    const resellerId = _parseQueryParam('reseller', false)
    if (resellerId && !Array.isArray(resellerId)) {
      Api.get(GET_RESELLER_INFO.replace('{id}', resellerId)).then((res) => {
        const { reseller } = res.data
        setReseller(reseller)
        if (reseller) {
          // load corresponding plans for current reseller
          Api.get(GET_RESELLER_PLANS.replace('{id}', resellerId)).then((res) => {
            const { plans } = res.data
            setSellerPlans(plans)
          }).catch((error) => {
            console.warn(error)
          })

          if (mode === SIGN_IN_MODE.SIGN_IN) {
            // automatically switch to dedicated user registration page if there is reseller info
            setMode(SIGN_IN_MODE.REGISTER_INFO_INPUT)
          }
        }
      }).catch((error) => {
        console.warn(error)
        // could not load reseller info
        setReseller(null)
      })
    } else {
      // no reseller info
      setReseller(null)
    }
  }, [mode, reseller])

  // load plans when needed
  useEffect(() => {
    Api.get(LIST_OF_AVAILABLE_PLANS).then((response) => {
      if (response.data?.success) {
        // pre-processing
        const plans = (response.data.plans || []).filter((p: TPlan) => !!p.amountJpy || p.productCode === 'FREE')
        setPlanOptions(plans)
      }
    }).catch((error) => {
      // log to sentry
      sentryLog(error)
      console.error('Can not list plans')
    })
  }, [])

  const switchSignIn = useCallback(() => {
    setSignInError('')
    setMode(SIGN_IN_MODE.SIGN_IN)
  }, [])

  const switchRegister = useCallback(() => {
    setSignInError('')
    setTosAgreed(false)
    setMode(SIGN_IN_MODE.REGISTER_INFO_INPUT)
  }, [])

  const switchForgotPassword = useCallback(() => {
    setSignInError('')
    setMode(SIGN_IN_MODE.FORGOT_PASSWORD)
  }, [])

  const btnText = useMemo(() => {
    switch (mode) {
      case SIGN_IN_MODE.REGISTER_INFO_INPUT:
        return t('プラン選択へ')
      case SIGN_IN_MODE.REGISTER_PLAN_SELECT:
        return t('登録')
      case SIGN_IN_MODE.FORGOT_PASSWORD:
        return t('送信')
      case SIGN_IN_MODE.SIGN_IN:
      default:
        return t('ログイン')
    }
  }, [mode, t])

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()

    if (mode === SIGN_IN_MODE.SIGN_IN) {
      const email = emailRef.current?.value.trim()
      const password = passwordRef.current?.value.trim()

      if (!email || !password) {
        setSignInError(SIGN_IN_ERROR.EMPTY)
      } else {
        setLoading(true)
        setSignInError('')
        try {
          await UserInfo.logIn(email, password)
        } catch (error: any) {
          setLoading(false)
          alert(t(error))
        }
      }
    } else if (mode === SIGN_IN_MODE.FORGOT_PASSWORD) {
      if (countDownRef.current > 0) {
        return
      }

      const email = emailRef.current?.value.trim()

      if (email) {
        setLoading(true)
        UserInfo.forgotPassword(email).then(() => {
          startCountDown()
        }).catch(e => {
          alert(t(e.response.data.message))
        }).finally(() => {
          setLoading(false)
        })
      }
    } else if (mode === SIGN_IN_MODE.REGISTER_INFO_INPUT) {
      const email = emailRef.current?.value.trim()
      const password = passwordRef.current?.value.trim()

      if (!email || !password) {
        setSignInError(SIGN_IN_ERROR.EMPTY)
      } else {
        if (isAcceptablePassword(password)) {
          setSignInError('')

          // collect data
          let registerData: UserRegistrationData = {
            email,
            password,
          }

          if (isCompany) {
            // company account
            registerData = {
              ...registerData,
              isCompany: true,
              // empty values will be replaced with undefined, for server to treat them as optional
              name: nameRef.current?.value.trim() || undefined,
              nameKana: nameKanaRef.current?.value.trim() || undefined,
              company: companyRef.current?.value.trim() || undefined,
              companyKana: companyKanaRef.current?.value.trim() || undefined,
              dept: deptRef.current?.value.trim() || undefined,
              phone: phoneRef.current?.value.trim() || undefined,
              addr: addrRef.current?.value.trim() || undefined,
              resellerCode: resellerCodeRef.current?.value.trim() || undefined,
              resellerName: resellerNameRef.current?.value.trim() || undefined,
              userCode: userCodeRef.current?.value.trim() || undefined,
            }
          } else {
            // personal account
            registerData = {
              ...registerData,
              name: email,
            }
          }
          setRegInfo(registerData)

          // data validation
          setLoading(true)
          const ok = await UserInfo.validateData(registerData)
          setLoading(false)

          if (ok) {
            setMode(SIGN_IN_MODE.REGISTER_PLAN_SELECT)
          }
        } else {
          setSignInError(SIGN_IN_ERROR.WEAK_PASSWORD)
        }
      }
    } else if (mode === SIGN_IN_MODE.REGISTER_PLAN_SELECT) {
      setLoading(true)
      // setSignInError('')

      // register account
      const success = await UserInfo.register(regInfo, selectedPlan, reseller?.id)
      if (success) {
        switchSignIn()
      }
      setLoading(false)
    }
  }, [mode, SIGN_IN_ERROR.EMPTY, SIGN_IN_ERROR.WEAK_PASSWORD, t, countDownRef, startCountDown, isCompany, regInfo, selectedPlan, reseller?.id, switchSignIn])


  // SSO
  const handleGoogleSignin = async () => {
    setLoading(true)
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'consent',
    })
    await handlePopupSignIn(provider, 'Google')
    setLoading(false)
  }

  const handleMicrosoftSignin = async () => {
    setLoading(true)
    const provider = new OAuthProvider('microsoft.com')
    provider.setCustomParameters({
      prompt: 'consent',
    })
    await handlePopupSignIn(provider, 'Microsoft')
    setLoading(false)
  }

  return (
    <div className='container-fluid p-3' style={{ overflowY: 'auto' }}>
      <div className='row justify-content-center mt-4 mt-md-5'>
        <div className={`login-box donut-box col p-5 pt-4 col-sm-10${mode !== SIGN_IN_MODE.REGISTER_PLAN_SELECT ? ' col-md-8 col-lg-6 col-xl-5' : ''}`}>
          {mode !== SIGN_IN_MODE.SIGN_IN && (
            <div className='row mb-3 align-items-center'>
              <div className='col-auto'>
                <i className='fa-solid fa-chevron-left h3 mb-0'
                  onClick={mode === SIGN_IN_MODE.REGISTER_PLAN_SELECT ? switchRegister : switchSignIn} />
              </div>
              <div className='col text-primary text-center h3 mb-0 px-0'>
                <b>
                  {mode === SIGN_IN_MODE.FORGOT_PASSWORD ? (
                    t('パスワード再設定')
                  ) : (
                    !reseller ? t('新規会員登録') : t('新規会員登録・代理店', { reseller: reseller.name })
                  )}
                </b>
              </div>
            </div>
          )}

          <form className={`email-provider-form d-flex flex-column align-items-center${loading ? ' hidden' : ''}`} onSubmit={handleSubmit}>
            <div className='form-group mb-2 mt-4 full-width' style={{ display: mode !== SIGN_IN_MODE.REGISTER_PLAN_SELECT ? 'block' : 'none' }}>
              <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
                <label htmlFor='signin-email' className='text-primary ms-3'>
                  <b>{t('メールアドレス')}</b>
                </label>
              </div>
              <input type='email' className='form-control' id='signin-email'
                ref={emailRef} placeholder='you@email.com' />
            </div>
            {mode !== SIGN_IN_MODE.FORGOT_PASSWORD && (
              <div className='form-group mb-2 mt-4 full-width' style={{ display: mode !== SIGN_IN_MODE.REGISTER_PLAN_SELECT ? 'block' : 'none' }}>
                <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
                  <label htmlFor='signin-password' className='text-primary ms-3'>
                    <b>{t('パスワード')}</b>
                  </label>
                </div>
                <PasswordInput passwordRef={passwordRef} />
                {mode === SIGN_IN_MODE.SIGN_IN && (
                  <label className='text-link text-secondary text-end full-width' onClick={switchForgotPassword}>
                    <small>{t('パスワードを忘れた')}</small>
                  </label>
                )}
              </div>
            )}
            {(mode === SIGN_IN_MODE.REGISTER_INFO_INPUT || mode === SIGN_IN_MODE.REGISTER_PLAN_SELECT) && (
              <>
                {mode === SIGN_IN_MODE.REGISTER_INFO_INPUT && (
                  <div className='form-check mt-4 full-width'>
                    <input className='form-check-input' type='checkbox' id='check-company'
                      checked={isCompany} onChange={handleIsCompanyChange} />
                    <label className='form-check-label' htmlFor='check-company'>
                      {t('法人の場合')}
                    </label>
                  </div>
                )}

                <CompanyFields mode={mode} isCompany={isCompany} nameRef={nameRef} nameKanaRef={nameKanaRef}
                  companyRef={companyRef} companyKanaRef={companyKanaRef} deptRef={deptRef} phoneRef={phoneRef}
                  addrRef={addrRef} resellerCodeRef={resellerCodeRef} resellerNameRef={resellerNameRef} userCodeRef={userCodeRef} />
              </>
            )}
            {mode === SIGN_IN_MODE.REGISTER_PLAN_SELECT && (
              // need to wait until reseller info has been loaded
              ((planOptions.length && reseller === null) || (!!reseller && !!sellerPlans)) ? (
                <>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-auto'>
                        {t('メールアドレス')}：
                      </div>
                      <div className='col'>
                        <b>{regInfo.email}</b>
                      </div>
                    </div>
                  </div>
                  {/* even if reseller is specified, sellerPlans can be an empty array which indicates all plans are available for that reseller */}
                  <PlanSelection plans={reseller ? ((sellerPlans!.length > 0) ? sellerPlans! : planOptions) : planOptions} onPlanSelect={setSelectedPlan} />
                </>
              ) : (
                <div className='row justify-content-center'>
                  <div className='col-auto py-5'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </div>
              )
            )}
            {mode === SIGN_IN_MODE.FORGOT_PASSWORD && (
              <small className='mb-1 mt-2 text-center text-secondary d-block'>
                {t('登録したメールアドレスにパスワード再設定用のURLを送ります。')}
              </small>
            )}

            {!!signInError && (
              <small className='text-danger mt-2' style={{ maxWidth: 360 }}>{signInError}</small>
            )}

            {mode === SIGN_IN_MODE.REGISTER_INFO_INPUT && (
              <EmbeddedTos agreed={tosAgreed} onChange={(agreed) => setTosAgreed(agreed)} />
            )}

            <div className='d-flex flex-column align-items-stretch'>
              <button type='submit' disabled={loading || (!!countDown && mode === SIGN_IN_MODE.FORGOT_PASSWORD) || (!tosAgreed && mode === SIGN_IN_MODE.REGISTER_INFO_INPUT)}
                className='btn btn-primary bg-grad-primary mt-4 px-5'>
                {/* {mode === SIGN_IN_MODE.SIGN_IN && <i className='fa-solid fa-envelope' />} */}
                {btnText && (
                  (!countDown || mode !== SIGN_IN_MODE.FORGOT_PASSWORD) ? btnText : `${btnText} (${countDown})`
                )}
              </button>
              {mode === SIGN_IN_MODE.SIGN_IN && (
                <>
                  <button type='button' className='btn btn-outline-primary mt-3' onClick={switchRegister}>
                    {t('新規アカウントを作成')}
                  </button>

                  <div className='d-flex justify-content-center mt-3'>又は</div>

                  <CustomLoginButton onClick={handleGoogleSignin} title={t('Googleでログイン')} shortTitle='Google'
                    iconUrl='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg' />

                  <CustomLoginButton onClick={handleMicrosoftSignin} title={t('Microsoftでログイン')} shortTitle='Microsoft'
                    iconUrl='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg' />
                </>
              )}
            </div>
          </form>

          {loading && (
            <div className='row justify-content-center'>
              <div className='col-auto py-5'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginPage
