// import firebase from 'firebase/compat/app'
// import { auth } from 'firebaseui'

import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
}

const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
export const Auth = getAuth(app)

if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
  console.log('Using Firebase Emulator')
  connectFirestoreEmulator(firestore, 'localhost', 8080)
  connectAuthEmulator(Auth, 'http://localhost:9099')
}

// export const AuthUI = new auth.AuthUI(Auth)
// export default firebase
