import './App.css';

import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { AxiosProgressEvent } from 'axios';

import UserInfo from './store/UserInfo';
import HomePage from './page/home/HomePage';
import LoginPage from './page/login/LoginPage';
import { Contact } from './page/contact/Contact';
import { Questions } from './page/questions/Questions';
import { Policies } from './page/policies/Policies';
import { Contract } from './page/policies/Contract';
import { PrivacyPolicy } from './page/policies/PrivacyPolicy';
import Logo from './logo.png';
import { EmailConfirm } from './page/emailConfirm/EmailConfirm';
import { ResetPassword } from './page/resetPassword/ResetPassword';
import LoadingButton from './view/LoadingButton';
import { createMeetingFromAudioData, createMeetingUrl } from './api/MeetingApi';
import { BASE_ROUTES } from './Routes';
import { BUTTON_MODES, hideAll, show, SIZE_MODE } from './view/PopupEvent';
import { className } from './util/className';
import TimeCounter from './view/TimeCounter';
import { Subscribe } from './page/subscribeForm/Subscribe';

import { LoadingOverlay } from './view/LoadingOverlay';
import { calcDiffMinutes, convertDateTimeToString } from './util/date';
import { sentryLog } from './util/sentry';
import { convertI18nToILanguages, ILanguages, LanguageList } from './constant/Languages';
import { getNotifications, NotificationFirebaseData, readNotifications } from './api/MeetingFirebaseApi';
import { FileUpload, FileUploadHandle } from './view/FileUpload';
import { SelectPreviousMeeting } from './page/MeetingList/SelectPreviousMeeting';
import { Auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth';
import { paramShareWindow, titleMaxLength } from './constant/Variables';
import { Meeting } from './store/model/Meeting';

/**
 * This component is only used for exposing history object of react-router-dom, for navigating after meeting created.
 */
const ForNavigation = ({ historyRef }: { historyRef: MutableRefObject<any> }) => {
  const history = useHistory();
  historyRef.current = history;
  return null;
};


function FilterTime(n: number) {
  return UserInfo.isUnlimitedPlan() || (n * 60 <= UserInfo.gpTechRemainingMeetingTime);
}


export const MeetingCreateModal = observer((
  { meetings, historyRef, setCreating }: { meetings: Meeting[], historyRef: MutableRefObject<any>, setCreating: (b: boolean) => void },
) => {
  const { t, i18n } = useTranslation();

  const MIN_MEETING_DURATION = 1;
  const DEFAULT_MAX_MEETING_DURATION = 300; // Minutes Count. default 5 hours.

  const userRemainingMeetingDuration = UserInfo.isUnlimitedPlan() ? Infinity : UserInfo.gpTechRemainingMeetingTime;
  const maxMeetingDuration = Math.min(Math.max(0, Math.floor(userRemainingMeetingDuration / 60)), 60 * 5); // Minutes Count. Max -- 5 hours.

  const now = new Date();
  const initialBeginAt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0);

  const defaultTitle = `${UserInfo.displayName}${t('の会議')} ${new Date().toLocaleDateString()}`;

  const [title, setTitle] = useState(defaultTitle);
  const [allocatedMinutes, setAllocatedMinutes] = useState(60);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isValidAudioFile, setIsValidAudioFile] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [percentage, setPercentage] = useState<number | undefined>();
  const [scheduledBeginAt, setScheduledBeginAt] = useState(convertDateTimeToString(initialBeginAt));
  const [scheduledEndAt, setScheduledEndAt] = useState(convertDateTimeToString(initialBeginAt, allocatedMinutes));
  const [maxEndAt, setMaxEndAt] = useState(convertDateTimeToString(initialBeginAt, maxMeetingDuration));
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([convertI18nToILanguages(i18n.language)]);
  const [selectedTranslateLanguage, setSelectedTranslateLanguage] = useState<string>(convertI18nToILanguages(i18n.language));

  const selectOptionRef = useRef<HTMLSelectElement>(null);

  const agendaFileUploadRef = useRef<FileUploadHandle>(null);
  const advanceDocumentsFileUploadRef = useRef<FileUploadHandle>(null);
  const audioAgendaFileUploadRef = useRef<FileUploadHandle>(null);
  const audioAdvanceDocumentsFileUploadRef = useRef<FileUploadHandle>(null);
  const audioFileUploadRef = useRef<FileUploadHandle>(null);

  const selectedLanguagesRef = useRef<string[]>(selectedLanguages);
  const selectedTranslateLanguageRef = useRef<string>(selectedTranslateLanguage);

  const [selectedMeetingIds, setSelectedMeetingIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    selectedLanguagesRef.current = selectedLanguages;
  }, [selectedLanguages]);
  useEffect(() => {
    selectedTranslateLanguageRef.current = selectedTranslateLanguage;
  }, [selectedTranslateLanguage]);

  const validateAndSetInputBeginAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.currentTarget.value);
    const beginAt = new Date(convertDateTimeToString(date, allocatedMinutes));

    setScheduledBeginAt(convertDateTimeToString(date));
    setScheduledEndAt(convertDateTimeToString(beginAt));
    setMaxEndAt(convertDateTimeToString(beginAt, maxMeetingDuration));
    setAllocatedMinutes(calcDiffMinutes(date, beginAt));

    if (selectOptionRef.current) {
      const element: HTMLSelectElement = selectOptionRef.current;
      element.value = 'automatic';
    }
  };

  const validateAndSetInputEndAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.currentTarget.value);
    const beginAt = new Date(scheduledBeginAt);
    if (date < beginAt) {
      alert(t('開始時刻より未来の日時を選択してください'));
      return;
    }
    if (date > new Date(maxEndAt)) {
      if (maxMeetingDuration === DEFAULT_MAX_MEETING_DURATION) {
        alert(t('会議の最大時間は5時間です。5時間以内の日時を選択してください'));
      } else {
        alert(t('会議の残り時間を超えています'));
      }
      return;
    }
    setScheduledEndAt(convertDateTimeToString(date));
    setAllocatedMinutes(calcDiffMinutes(beginAt, date));
    if (selectOptionRef.current) {
      const element: HTMLSelectElement = selectOptionRef.current;
      element.value = 'automatic';
    }
  };

  const validateAndSetAllocatedMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);
    if (Number.isInteger(value) && MIN_MEETING_DURATION <= value && value <= maxMeetingDuration) {
      if (selectOptionRef.current) {
        const element: HTMLSelectElement = selectOptionRef.current;
        element.value = 'automatic';
      }

      setAllocatedMinutes(value);
      setScheduledEndAt(convertDateTimeToString(new Date(scheduledBeginAt), value));
    }
  };

  const validateAndSetSelectedMinutes = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value);
    if (Number.isInteger(value) && MIN_MEETING_DURATION <= value && value <= maxMeetingDuration) {
      setAllocatedMinutes(value);
      setScheduledEndAt(convertDateTimeToString(new Date(scheduledBeginAt), value));
    }
  };

  const meetingAlreadyExistsMessage = i18next.t('ミーティングが既に存在しています。');

  // OpenAI Vector Store API で対応しているデータの種類
  // ※ docx, pdf, txt のみ対応という要件のため、それ以外はコメントアウト
  const acceptFile = {
    //'c': 'text/x-c',
    //'cs': 'text/x-csharp',
    //'cpp': 'text/x-c++',
    //'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //'html': 'text/html',
    //'java': 'text/x-java',
    //'json': 'application/json',
    //'md': 'text/markdown',
    'pdf': 'application/pdf',
    //'php': 'text/x-php',
    //'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    //'py': 'text/x-python',
    //'rb': 'text/x-ruby',
    //'tex': 'text/x-tex',
    'txt': 'text/plain',
    //'css': 'text/css',
    //'js': 'text/javascript',
    //'sh': 'application/x-sh',
    //'ts': 'application/typescript'
  };

  // 文字起こし処理可能な動画形式
  // コメントアウトされている形式も可能らしいがすべてサポートするのは大変そうなので限定する
  const supportedVideoMimeTypes = {
    mp4: 'video/mp4',
    // webm: 'video/webm',
    // avi: 'video/x-msvideo',
    // mkv: 'video/x-matroska',
    mov: 'video/quicktime',
    flv: 'video/x-flv',
    wmv: 'video/x-ms-wmv',
    mpeg: 'video/mpeg',
    mpg: 'video/mpeg',
    '3gp': 'video/3gpp',
    ts: 'video/mp2t',
    m4v: 'video/x-m4v',
    ogv: 'video/ogg',
  };
  // 文字起こし処理可能な音声形式
  // コメントアウトされている形式も可能らしいがすべてサポートするのは大変そうなので限定する
  const supportedAudioMimeTypes = {
    mp3: 'audio/mpeg',
    // aac: 'audio/aac',
    wav: 'audio/wav',
    // flac: 'audio/flac',
    // ogg: 'audio/ogg',
    // wma: 'audio/x-ms-wma',
    m4a: 'audio/x-m4a',
    // opus: 'audio/opus',
    // mka: 'audio/x-matroska',
    // amr: 'audio/amr',
    // aiff: 'audio/aiff',
  };

  // 受け入れられるファイル個数かどうか確認する
  const validateUploadFileCounts = (files: FileList | null | undefined, limit: number) => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;

    if (files.length > limit) {
      alert(i18next.t('同時にアップロード可能なファイル個数の上限は{{limit}}個に設定されています', { limit: limit }));
      return false;
    }
    return true;
  };

  // 受け入れられるファイルサイズかどうか確認する
  const validateUploadFileSize = (files: FileList | null | undefined, limit: number, unit: 'MB' | 'GB' = 'MB') => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;
    // ファイルを取得
    const filesArray = Array.from(files);
    let total = 0;
    for (const file of filesArray) {
      total += file.size;
      if (total >= limit) {
        let limitStr = (limit / (1024 * 1024)).toFixed(0) + 'MB';
        if (unit === 'GB') {
          limitStr = (limit / (1024 * 1024 * 1024)).toFixed(0) + 'GB';
        }
        alert(i18next.t('アップロード可能なファイルサイズは{{limit}}未満に設定されています', { limit: limitStr }));
        return false;
      }
    }
    return true;
  };

  // ファイルの拡張子で受け入れられるファイルかどうか確認する
  const validateUploadFileExtension = (files: FileList | null | undefined, acceptFile: string[]) => {
    // ファイルがなければ終了
    if (!files || files?.length === 0) return true;
    // ファイルを取得
    const filesArray = Array.from(files);
    for (const file of filesArray) {
      const ext = file.name.split('.').at(-1);
      if (!ext || !acceptFile.includes(ext)) {
        alert(i18next.t('このファイルは非対応です'));
        return false;
      }
    }
    return true;
  };

  const validateUploadAdvanceDocuments = (files: FileList | null | undefined) => {
    // ファイル個数
    if (!validateUploadFileCounts(files, 10)) {
      return false;
    }
    // ファイルサイズ
    if (!validateUploadFileSize(files, 10485760)) {
      return false;
    }
    // ファイル形式
    if (!validateUploadFileExtension(files, Object.keys(acceptFile))) {
      return false;
    }
    return true;
  };

  const validateUploadAgenda = (files: FileList | null | undefined) => {
    // ファイル個数
    if (!validateUploadFileCounts(files, 1)) {
      return false;
    }
    // ファイルサイズ
    if (!validateUploadFileSize(files, 1048576)) {
      return false;
    }
    // ファイル形式
    if (!validateUploadFileExtension(files, ['txt'])) {
      return false;
    }
    return true;
  };

  const validateUploadAudio = (files: FileList | null | undefined) => {
    // ファイル個数
    if (!validateUploadFileCounts(files, 1)) {
      return false;
    }
    // ファイルサイズ
    if (!validateUploadFileSize(files, 10737418240, 'GB')) {
      return false;
    }
    // ファイル形式
    if (!validateUploadFileExtension(files, [...Object.keys(supportedAudioMimeTypes), ...Object.keys(supportedVideoMimeTypes)])) {
      return false;
    }
    return true;
  };

  const handledAdvanceDocumentsFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    return validateUploadAdvanceDocuments(files);
  };

  const handleAgendaFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    return validateUploadAgenda(files);
  };

  const handledAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const valid = validateUploadAudio(files);
    setIsValidAudioFile(valid);
    return valid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsDisabled(true);

    // 不正な状態・値の場合は送信しない
    if (isDisabled) {
      return;
    }
    if (!e.currentTarget.checkValidity()) {
      return;
    }
    if (!Number.isInteger(allocatedMinutes)) {
      return;
    }
    if (!validateUploadAgenda(agendaFileUploadRef.current?.getFiles())) {
      return;
    }
    if (!validateUploadAdvanceDocuments(advanceDocumentsFileUploadRef.current?.getFiles())) {
      return;
    }

    setIsActive(true);
    e.stopPropagation();
    e.preventDefault();

    const enableAIAdvisor = process.env.REACT_APP_ENABLE_AI_ADVISOR === 'true';
    createMeetingUrl(title, scheduledBeginAt, scheduledEndAt, allocatedMinutes * 60, enableAIAdvisor, Array.from(selectedMeetingIds), agendaFileUploadRef.current?.getFiles(), advanceDocumentsFileUploadRef.current?.getFiles(), title === defaultTitle).then(resp => {
      if (resp.data.success) {
        historyRef.current?.push(BASE_ROUTES.LIST);
        // Simply Update it locally for now.
        // Remote data isn't far off from this

        UserInfo.refreshTime().then(() => hideAll());
      } else {
        window.alert(t(resp.data.message) || meetingAlreadyExistsMessage);
      }
    }).catch(err => {
      // log to sentry
      sentryLog(err);
      if (err.response && err.response.data) {
        if (err.response.data.message) {
          window.alert(t(err.response.data.message) || meetingAlreadyExistsMessage);
        }
      } else if (typeof err === 'string') {
        window.alert(err);
      }
    }).finally(() => {
      setIsDisabled(false);
      setCreating(false);
      setIsActive(false);
    });
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.total) return;
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setPercentage(percentCompleted);
  }

  const handleFileSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsDisabled(true);

    // 不正な状態・値の場合は送信しない
    if (isDisabled) {
      return;
    }
    if (!e.currentTarget.checkValidity()) {
      return;
    }
    if (!selectedLanguagesRef.current || selectedLanguagesRef.current.length === 0) {
      return;
    }
    if (!selectedTranslateLanguageRef.current) {
      return;
    }
    if (!audioFileUploadRef.current) {
      return;
    }
    const audio = audioFileUploadRef.current.getFiles();
    if (!audio) {
      return;
    }
    if (!validateUploadAudio(audioFileUploadRef.current.getFiles())) {
      return;
    }
    if (!validateUploadAgenda(audioAgendaFileUploadRef.current?.getFiles())) {
      return;
    }
    if (!validateUploadAdvanceDocuments(audioAdvanceDocumentsFileUploadRef.current?.getFiles())) {
      return;
    }

    if (selectedLanguagesRef.current.length === 0) {
      selectedLanguagesRef.current.push(ILanguages['ja-JP'])
    }

    setIsActive(true);
    e.stopPropagation();
    e.preventDefault();

    const enableAIAdvisor = process.env.REACT_APP_ENABLE_AI_ADVISOR === 'true';
    createMeetingFromAudioData(title, selectedLanguagesRef.current, selectedTranslateLanguageRef.current, scheduledBeginAt, enableAIAdvisor, Array.from(selectedMeetingIds), audio, audioAgendaFileUploadRef.current?.getFiles(), audioAdvanceDocumentsFileUploadRef.current?.getFiles(), title === defaultTitle, onUploadProgress).then(async resp => {
      if (resp.data.success) {
        await show({content: t('議事録作成を開始しました。完了までしばらくお待ち下さい。')});
        historyRef.current?.push(BASE_ROUTES.LIST);
        // Simply Update it locally for now.
        // Remote data isn't far off from this

        UserInfo.refreshTime().then(() => hideAll());
      } else {
        window.alert(t(resp.data.message) || meetingAlreadyExistsMessage);
      }
    }).catch(err => {
      console.log(err)
      // log to sentry
      sentryLog(err);
      if (err.response && err.response.data) {
        if (err.response.data.message) {
          window.alert(t(err.response.data.message) || meetingAlreadyExistsMessage);
        }
      } else if (typeof err === 'string') {
        window.alert(err);
      }
    }).finally(() => {
      setIsDisabled(false);
      setCreating(false);
      setIsActive(false);
      setPercentage(undefined);
    });
  };

  const buttonClassName = className('flex-shrink-1 btn', {
    'btn-success': !isDisabled,
    'btn-secondary': isDisabled,
  });

  const selectMinuteOptions: Array<number> = [
    15,
    30,
    60,
    90,
    120,
    150,
    180,
    210,
    240,
    270,
    300,
  ];

  const onSelectPreviousMeeting = useCallback((meetingIds: Set<string>) => {
    setSelectedMeetingIds(meetingIds)
  }, []);

  const onClickSelectedMeetingIds = useCallback( async () => {
    await show({
      title: t('関連する会議'),
      content: <SelectPreviousMeeting
        initSelectedMeetingIds={selectedMeetingIds}
        onSelect={onSelectPreviousMeeting} />,
      btnMode: BUTTON_MODES.NONE,
      size: SIZE_MODE.XLARGE,
    });
  }, [onSelectPreviousMeeting, selectedMeetingIds, t]);

  return (
    <>
      <ul className="nav nav-tabs" id="createMeetingTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="realtime-tab" data-bs-toggle="tab" data-bs-target="#realtime-tab-pane"
                  type="button" role="tab" aria-controls="realtime-tab-pane" aria-selected="true">{t('リアルタイム')}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="file-tab" data-bs-toggle="tab" data-bs-target="#file-tab-pane"
                  type="button" role="tab" aria-controls="file-tab-pane" aria-selected="false">{t('音声から作成')}
          </button>
        </li>
      </ul>
      <div className="tab-content" id="createMeetingTabContent">
        <div className="tab-pane fade show active" id="realtime-tab-pane" role="tabpanel" aria-labelledby="realtime-tab"
             tabIndex={0}>
          <form onSubmit={handleSubmit} noValidate>
            <div className="mb-3 row pt-3">
              <label htmlFor="title" className="col-sm-4 col-form-label">
                {t('タイトル')}
              </label>
              <div className="col-sm-8">
                <input
                  required
                  maxLength={titleMaxLength}
                  id="title"
                  type="text"
                  value={title}
                  className="form-control"
                  onChange={(e) => setTitle(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="scheduledtBeginAt" className="col-sm-4 col-form-label">
                {t('開始時刻')}
              </label>

              <div className="col-sm-8">
                <input
                  required
                  id="scheduledtBeginAt"
                  className="form-control"
                  type="datetime-local"
                  value={scheduledBeginAt}
                  onChange={validateAndSetInputBeginAt}
                  min={convertDateTimeToString(initialBeginAt)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="inputEndAt" className="col-sm-4 col-form-label">
                {t('終了時刻')}
              </label>

              <div className="col-sm-8">
                <input
                  required
                  id="inputEndAt"
                  className="form-control"
                  type="datetime-local"
                  value={scheduledEndAt}
                  onChange={validateAndSetInputEndAt}
                  min={scheduledBeginAt}
                  max={maxEndAt}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="selectMeetingIds" className="col-sm-4 col-form-label">
                {t('関連する会議')}
              </label>
              <div className="col-sm-8">
                <button
                  id="selectMeetingIds"
                  type="button"
                  className="btn btn-primary d-block w-100"
                  onClick={() => onClickSelectedMeetingIds()}
                >
                  {t('選択中')}: {selectedMeetingIds.size} {t('件')}
                </button>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <label htmlFor="allocatedTime" className="col-sm-4 col-form-label">
                  {t('割り当て時間(分)')}
                </label>

                <label htmlFor="allocatedTime" className="col-sm-8 col-form-label">
                  {allocatedMinutes} {t('分')}
                </label>
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <select
                    ref={selectOptionRef}
                    className="form-select"
                    onChange={validateAndSetSelectedMinutes}
                  >
                    <option value="automatic">
                      {t('手動設定')} ({allocatedMinutes} {t('分')})
                    </option>
                    {selectMinuteOptions
                      .filter(FilterTime)
                      .map((time =>
                          <option key={time} value={time}>
                            <TimeCounter second={time * 60} prefix="" wrapElement={false} />
                          </option>
                      ))
                    }
                  </select>
                </div>
              </div>

              <input
                type="range"
                min={MIN_MEETING_DURATION}
                max={maxMeetingDuration}
                id="allocatedTime"
                className="form-range"
                value={allocatedMinutes}
                onChange={validateAndSetAllocatedMinutes}
              />

              {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
              {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="agendaFileUpload" className="col-form-label">
                      {t('アジェンダのアップロード')}
                    </label>
                    <FileUpload
                      ref={agendaFileUploadRef}
                      onFileChange={handleAgendaFileChange} />
                  </div>
                </div>
              )}

              {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
              {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="advanceDocumentsFileUpload" className="col-form-label">
                      {t('事前資料のアップロード')}
                    </label>
                    <FileUpload
                      ref={advanceDocumentsFileUploadRef}
                      accept={Object.values(acceptFile).join(',')}
                      multiple={true}
                      onFileChange={handledAdvanceDocumentsFileChange} />
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex">
              <div className="flex-fill"></div>
              <button
                type="submit"
                className={buttonClassName}
                disabled={isDisabled}
              >
                {t('作成')}
              </button>
            </div>
          </form>
        </div>
        <div className="tab-pane fade" id="file-tab-pane" role="tabpanel" aria-labelledby="file-tab"
             tabIndex={0}>
          <form onSubmit={handleFileSubmit} noValidate>
            <div className="mb-3 row pt-3">
              <label htmlFor="title" className="col-sm-4 col-form-label">
                {t('タイトル')}
              </label>
              <div className="col-sm-8">
                <input
                  required
                  id="title"
                  type="text"
                  value={title}
                  className="form-control"
                  onChange={(e) => setTitle(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="title" className="col-sm-4 col-form-label">
                {t('使用言語')}
              </label>
              <div className="col-sm-8">
                <div className="list-group-container">
                  <ul className="list-group">
                    {Object.entries(LanguageList).map(([key, item], index) => (
                      <li className="list-group-item" key={index}>
                        <label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.sttLanguageCode}
                            checked={selectedLanguages.includes(item.sttLanguageCode)}
                            disabled={selectedLanguages.length >= 3 && !selectedLanguages.includes(item.sttLanguageCode)}
                            onChange={e => {
                              const checked = e.target.checked;
                              const currentSelection = [...selectedLanguages];

                              if (checked) {
                                setSelectedLanguages([...currentSelection, item.sttLanguageCode]);
                              } else {
                                setSelectedLanguages(
                                  currentSelection.filter(code => code !== item.sttLanguageCode),
                                );
                              }
                            }} />
                          <img src={item.img} alt={item.languageName} />
                          {item.languageName}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="title" className="col-sm-4 col-form-label">
                {t('翻訳言語')}
              </label>
              <div className="col-sm-8">
                <select
                  className="form-select bg-flag"
                  value={selectedTranslateLanguage}
                  onChange={e => setSelectedTranslateLanguage(e.target.value)}
                  style={{ backgroundImage: `url(${LanguageList[selectedTranslateLanguage as ILanguages].img})` }}
                >
                  {Object.entries(LanguageList).map(([key, item], index) => (
                    <option value={item.sttLanguageCode} key={index}>
                      {item.languageName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="scheduledtBeginAt" className="col-sm-4 col-form-label">
                {t('開始時刻')}
              </label>

              <div className="col-sm-8">
                <input
                  required
                  id="scheduledtBeginAt"
                  className="form-control"
                  type="datetime-local"
                  value={scheduledBeginAt}
                  onChange={validateAndSetInputBeginAt}
                />
              </div>
            </div>
            {UserInfo.meetings && UserInfo.meetings.loaded && UserInfo.meetings.dataArray.length && (
                <div className="mb-3 row">
                  <label htmlFor="selectMeetingIds" className="col-sm-4 col-form-label">
                    {t('関連する会議')}
                  </label>
                  <div className="col-sm-8">
                    <button
                      id="selectMeetingIds"
                      type="button"
                      className="btn btn-primary d-block w-100"
                      onClick={() => onClickSelectedMeetingIds()}
                    >
                      {t('選択中')}: {selectedMeetingIds.size} {t('件')}
                    </button>
                  </div>
                </div>
            )}

            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="audioFileUpload" className="col-form-label">
                  {t('会議音声ファイルのアップロード')}
                </label>
                <FileUpload
                  ref={audioFileUploadRef}
                  required
                  accept={[...Object.values(supportedAudioMimeTypes), ...Object.values(supportedVideoMimeTypes)].join(',')}
                  onFileChange={handledAudioFileChange} />
              </div>
            </div>

            {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
            {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
              <div className="row mb-3">
                <div className="col-12">
                  <label htmlFor="agendaFileUpload" className="col-form-label">
                    {t('アジェンダのアップロード')}
                  </label>
                  <FileUpload
                    ref={audioAgendaFileUploadRef}
                    onFileChange={handleAgendaFileChange} />
                </div>
              </div>
            )}

            {/* 本番環境ではない場合は使用可能 本番環境の場合はアンリミテッドプランLのみ使用可能 */}
            {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
              <div className="row mb-3">
                <div className="col-12">
                  <label htmlFor="advanceDocumentsFileUpload" className="col-form-label">
                    {t('事前資料のアップロード')}
                  </label>
                  <FileUpload
                    ref={audioAdvanceDocumentsFileUploadRef}
                    accept={Object.values(acceptFile).join(',')}
                    multiple={true}
                    onFileChange={handledAdvanceDocumentsFileChange} />
                </div>
              </div>
            )}
            <div className="d-flex">
              <div className="flex-fill"></div>
              <button
                type="submit"
                className={buttonClassName}
                disabled={isDisabled || !isValidAudioFile}
              >
                {t('作成')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <LoadingOverlay active={isActive} percentage={percentage} />
    </>
  );
});

const TimeCounterForRemainingTime = observer(() => {
  const { t } = useTranslation();

  return UserInfo.isUnlimitedPlan() ? (
    <span className="text-danger">{t('時間無制限')}</span>
  ) : (
    <TimeCounter second={UserInfo.currentSub?.remainingTime || 0} />
  );
});

const LanguageOptions = () => {
  const { i18n } = useTranslation();
  const onChange = (lang: string) => {
    // 言語設定変更
    i18n.changeLanguage(lang);
    // htmlの言語設定も変更する
    document.documentElement.setAttribute('lang', lang);
    // ローカルストレージに保管
    localStorage.setItem('language', lang);
  };
  const language = i18n.language; // This value ONLY changes when you pick onChange above. it's safe.

  return (
    <select onChange={(val) => onChange(val.currentTarget.value)} value={language}>
      <option lang="ja" value="ja">日本語</option>
      <option lang="en" value="en">English</option>
      <option lang="zh-CN" value="zh-CN">简体中文</option>
      <option lang="zh-TW" value="zh-TW">繁體中文</option>
      <option lang="id" value="id">Bahasa Indonesia</option>
    </select>
  );
};

function App() {
  const [creating, setCreating] = useState(false);
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationFirebaseData[]>([]);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [load, setLoad] = useState<boolean>(false);

  const historyRef = useRef<any>();

  // オーナーIDを「clipear-meeting-web」に送信
  const params = new URLSearchParams(window.location.search);
  const paramShare = params.get(paramShareWindow);
  if (paramShare === '1' && UserInfo.loggedIn && UserInfo.id && process.env.REACT_APP_MEETING_URL) {
    const url = new URL(process.env.REACT_APP_MEETING_URL);
    window.parent.postMessage({ ownerId: UserInfo.id }, url.origin);
  }

  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const startSync = useCallback(() => {
    // ユーザー情報を同期する関数
    const syncUserInfo = async () => {
      try {
        if (
          UserInfo.id &&
          UserInfo.meetings &&
          !UserInfo.meetings.loaded &&
          typeof UserInfo.meetings.startSync === "function"
        ) {
          UserInfo.meetings.startSync(setMeetings);
          await getNotifications(UserInfo.id, setNotifications);
          setLoad(true);
          return true; // 成功時
        }
        return false; // 条件未達時
      } catch (error) {
        console.error("Failed to sync user info: ", error);
        return false; // エラー時
      }
    };

    // 初回の同期実行とポーリング開始
    const initiateSync = async () => {
      const success = await syncUserInfo();
      if (!success) {
        intervalRef.current = setInterval(async () => {
          const pollSuccess = await syncUserInfo();
          if (pollSuccess && intervalRef.current) {
            clearInterval(intervalRef.current); // 条件満たすと停止
            intervalRef.current = null;
          }
        }, 500);
      }
    };

    initiateSync();

    // クリーンアップ処理
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      UserInfo.meetings?.stopSync();
    };
  }, []);

  useEffect(() => {
    startSync();
    window.addEventListener('loggedIn', startSync);
    return () => {
      // リスナー解除
      window.removeEventListener('loggedIn', startSync);
      UserInfo.meetings?.stopSync()
    };
  }, [startSync]);

  const handleCreateUrl = () => {
    show({
      content: <MeetingCreateModal
        meetings={meetings}
        historyRef={historyRef}
        setCreating={setCreating}
      />,
      title: t('新しく会議を作る'),
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
      center: false,
    });
  };

  const handleNotifyClick = async (notify: NotificationFirebaseData) => {
    await readNotifications(UserInfo.id, notify._id);
    if (notify.link) {
      window.location.href = notify.link;
    } else if (notify.meeting) {
      document.dispatchEvent(new CustomEvent('showMeeting', {detail: {meeting: notify.meeting}}))
    }
  }

  // handle sign in using Firebase Authentication
  useEffect(() => {
    const cancel = onAuthStateChanged(Auth, (user) => {
      UserInfo.setFUser(user)
    })

    return cancel
  }, [])

  return (
    <div className="App">
      <div className="home-page">
        <div className="header donut-box-thick p-2">
          <div className="flex-grow-1">
            <img className="logo" src={Logo} alt="Donut Robotics" />
          </div>

          <div className="flex-shrink-1">
            <TimeCounterForRemainingTime />
          </div>
          <div className="col-auto text-primary row ms-auto">
            <div className="col-auto text-ellipsis">
              <div className="text-link">
                {/*<b><i className='bi bi-chevron-right' /> <small>利用規約・プライバシーポリシー</small></b>*/}

                {UserInfo.loggedIn && (
                  <div className="col-auto">
                    <LoadingButton className="rounded-pill d-none d-md-inline-block"
                                   isLoading={creating} onClick={handleCreateUrl} big>
                      {t('ミーティングを新規作成')}
                    </LoadingButton>
                    <LoadingButton className="rounded-pill d-inline-block d-md-none"
                                   isLoading={creating} onClick={handleCreateUrl}>
                      {t('新規作成')}
                    </LoadingButton>
                    <button className="btn d-inline-block notify" data-bs-toggle={`${load ? 'dropdown' : ''}`}>
                      <i className="bi bi-bell-fill fs-2 text-primary position-relative">
                        {notifications.findIndex(n => !n.read) !== -1 && (
                          <span
                            className="position-absolute translate-middle bg-danger border border-light rounded-circle"
                            style={{ padding: '6px', top: '8%', left: '74%' }}>
                          <span className="visually-hidden">New alerts</span>
                        </span>
                        )}
                      </i>
                    </button>
                    {load && (
                      <div className="notify-list dropdown-menu">
                        <ul>
                          {notifications.length === 0 ? (
                            <li>
                              <button className="dropdown-item">
                                <span className="fs-6 text-wrap">{t('通知はありません')}</span><br />
                              </button>
                            </li>
                          ) : (
                            notifications.map((notify, index) => (
                              <li key={index} className={`${notify.type}`}>
                                {notify.meeting || notify.link ? (
                                  <button className={`dropdown-item${notify.read ? '' : ' unread'}`}
                                          onClick={(e) => handleNotifyClick(notify)}>
                                    <span className="fs-4 text-wrap">{t(notify.title)}</span><br />
                                    <span className="fs-6 text-wrap">{notify.body}</span><br />
                                  </button>
                                ) : (
                                  <p className={`m-0 dropdown-item-text${notify.read ? '' : ' unread'}`}
                                          onClick={(e) => handleNotifyClick(notify)}>
                                    <span className="fs-4 text-wrap">{t(notify.title)}</span><br />
                                    <span className="fs-6 text-wrap">{notify.body}</span><br />
                                  </p>
                                )}
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!UserInfo.loggedIn && (
              <div className="col-auto text-ellipsis">
                <button onClick={() => historyRef.current?.push('/contact')} className="btn btn-link">
                  <b><i className="bi bi-envelope" /> <small>
                    {t('お問い合わせ')}
                  </small></b>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <div>
            <Router>
              <Route path="" exact={false}>
                <ForNavigation historyRef={historyRef} />
              </Route>

              {UserInfo.loggedIn === true && (
                <Switch>
                  <Route path="/contract" exact={true} component={Contract} />
                  <Route path="" exact={false} render={() => <HomePage notifications={notifications} meetings={meetings} />} />
                </Switch>
              )}
              {UserInfo.loggedIn === false && (
                <Switch>
                  <Route path="/email-confirmation" exact={true} component={EmailConfirm} />
                  <Route path="/reset-password" exact={true} component={ResetPassword} />
                  <Route path="/policies" exact={true} component={Policies} />
                  <Route path="/questions" exact={true} component={Questions} />
                  <Route path="/contract" exact={true} component={Contract} />
                  <Route path="/contact" exact={true} component={Contact} />
                  <Route path="/policies" exact={true} component={Policies} />
                  <Route path="/privacy" exact={true} component={PrivacyPolicy} />
                  <Route path={BASE_ROUTES.SUBSCRIBE} exact={true} component={Subscribe} />
                  {/* show loading overlay until Firebase Auth is initialized */}
                  <Route path='' exact={false} component={UserInfo.fUser === undefined ? (
                    () => <LoadingOverlay active={true} />
                  ) : LoginPage} />
                </Switch>
              )}
            </Router>
          </div>
        </div>
        <div className="text-primary row p-2 align-items-center">
          <div className="col-auto text-ellipsis">
            <LanguageOptions />
          </div>
          <div className="col-auto text-ellipsis">
            <button onClick={() => historyRef.current?.push('/policies')} className="btn btn-link">
              <b><small>{t('利用規約')}</small></b>
            </button>
          </div>
          <div className="col-auto text-ellipsis">
            <button onClick={() => historyRef.current?.push('/privacy')} className="btn btn-link">
              <b><small>{t('プライバシーポリシー')}</small></b>
            </button>
          </div>
          <div className="col-auto text-ellipsis">
            <button onClick={() => historyRef.current?.push('/subscribe')} className="btn btn-link">
              <b><small>{t('プラン一覧')}</small></b>
            </button>
          </div>
          <div className="col-auto text-ellipsis">
            <button onClick={() => historyRef.current?.push('/questions')} className="btn btn-link">
              <b><small>{t('よくある質問')}</small></b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default observer(App);
