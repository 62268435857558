import { Meeting } from './model/Meeting';
import CollectionStore from './CollectionStore';
import { toDate } from '../util/date';
import { makeObservable } from 'mobx';
import { MEETING_STATES, MEETING_STATES_TYPE } from '../api/MeetingFirebaseApi';

export class MeetingStore extends CollectionStore<Meeting> {
  constructor(collectionName: string, ModelClass: new (...args: any[]) => Meeting, needTimestamps = false) {
    super(collectionName, ModelClass, needTimestamps);
    makeObservable(this, {});
  }

  protected generateSearchableText(meeting: Meeting): string {
    const texts = [
      meeting.title,
      meeting.additionalDocument,
      meeting.realtimeMinutes?.map(m => m.markdown).join(' '),
      meeting.summary
    ].filter(Boolean);

    return texts.join(' ').toLowerCase();
  }

  // ステータスでのソート用にカスタムソート実装を追加
  protected customSort(a: Meeting, b: Meeting, field: string, direction: 'asc' | 'desc'): number {
    if (field === 'state') {
      const statusOrder: MEETING_STATES_TYPE[] = [
        MEETING_STATES.BUILDING,
        MEETING_STATES.FINISH,
        MEETING_STATES.WAITING,
        MEETING_STATES.INCALL,
        MEETING_STATES.COMPLETED,
      ];
      const indexA = statusOrder.indexOf(a.state as MEETING_STATES_TYPE);
      const indexB = statusOrder.indexOf(b.state as MEETING_STATES_TYPE);

      if (indexA === -1 || indexB === -1) {
        return -1;
      }

      const comparison = indexA - indexB;
      return direction === 'desc' ? -comparison : comparison;
    }

    // startTimeフィールドの特別処理
    if (field === 'startTime') {
      const dateA = toDate(a.beginAt ?? a.scheduledBeginAt ?? a.createdAt);
      const dateB = toDate(b.beginAt ?? b.scheduledBeginAt ?? b.createdAt);
      const comparison = dateA.getTime() - dateB.getTime();
      return direction === 'desc' ? -comparison : comparison;
    }

    return super.customSort(a, b, field, direction);
  }
}
