import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import "./Highlight.css";

const Highlight = ({ text, keywords, plainText = false }: {text: string, keywords: string[], plainText?: boolean}) => {
  // Split text into segments based on keywords
  const getHighlightedText = (text: string, keywords: string[]) => {
    if (!keywords.length) return [{ text, highlight: false }];

    const segments = [];
    let currentIndex = 0;

    // Sort keywords by length (descending) to handle overlapping matches
    const sortedKeywords = [...keywords]
      .filter(Boolean)
      .sort((a, b) => b.length - a.length);

    while (currentIndex < text.length) {
      let matchFound = false;

      for (const keyword of sortedKeywords) {
        const regex = new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
        regex.lastIndex = currentIndex;
        const match = regex.exec(text);

        if (match && match.index === currentIndex) {
          segments.push({
            text: match[0],
            highlight: true
          });
          currentIndex = regex.lastIndex;
          matchFound = true;
          break;
        }
      }

      if (!matchFound) {
        // Find next possible match
        let nextMatch = text.length;
        for (const keyword of sortedKeywords) {
          const regex = new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
          regex.lastIndex = currentIndex + 1;
          const match = regex.exec(text);
          if (match && match.index < nextMatch) {
            nextMatch = match.index;
          }
        }

        segments.push({
          text: text.slice(currentIndex, nextMatch),
          highlight: false
        });
        currentIndex = nextMatch;
      }
    }

    return segments;
  };

  const segments = getHighlightedText(text, keywords);

  if (plainText) {
    return (
      <span>
      {segments.map((segment, index) =>
        segment.highlight ? (
          <mark className="highlight" key={index}>{segment.text}</mark>
        ) : (
          <span key={index}>{segment.text}</span>
        )
      )}
    </span>
    );
  }

  const markdownText = segments.map(segment =>
    segment.highlight ? `<mark class="highlight">${segment.text}</mark>` : segment.text
  ).join('');

  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
      {markdownText}
    </ReactMarkdown>
  );
};

export default Highlight;
