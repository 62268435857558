import { observer } from 'mobx-react-lite'
import { className } from '../util/className'
import UserInfo from '../store/UserInfo'
import { useTranslation } from 'react-i18next'

const TimeCounter = ({ second, prefix, showTime, wrapElement = true, }: { second: number, prefix?: string, showTime?: boolean, wrapElement?: boolean }) => {
  const { t } = useTranslation()

  if (!UserInfo.loggedIn && !showTime) {
    return <div className="text-secondary">...</div>
  }

  let timeInSeconds = second
  const MINUTES = 60
  const HOURS = MINUTES * 60

  const kName = className({
    "text-primary": timeInSeconds > HOURS,
    "text-danger": timeInSeconds <= HOURS,
  })

  const sign = Math.sign(timeInSeconds)
  timeInSeconds = Math.abs(timeInSeconds)

  const inMinutes = Math.floor(timeInSeconds / MINUTES)

  const hours = Math.floor(timeInSeconds / HOURS)
  timeInSeconds = timeInSeconds % HOURS
  const minutes = Math.floor(timeInSeconds / MINUTES)

  let resultText = prefix ?? t("残り ")

  if (sign < 0) {
    resultText += '-'
  }
  if (hours > 0 || minutes > 0) {
    if (hours > 0) {
      resultText += t('time.hour', { count: hours })
    }
    if (minutes > 0) {
      resultText += t('time.minute', { count: minutes })
    }

    resultText += ` (${sign < 0 ? '-' : ''}${t('time.minute', { count: inMinutes })})`
  } else {
    resultText += t('time.second', { count: 0 })
  }

  if (wrapElement) {
    return (<div className={kName}>
      {resultText}
    </div>)
  }
  return (<>{resultText}</>)
}

export default observer(TimeCounter)
