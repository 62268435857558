import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { deleteRoomFile, getRoomFile, getRoomFileList, getRoomFiles } from '../../api/MeetingApi';
import { formatFileSize } from '../../util/Util';
import { hide } from '../../view/PopupEvent';
import { updateMeetingHasUploadedFiles } from '../../api/MeetingFirebaseApi';
import saveAs from 'file-saver';
import UserInfo from '../../store/UserInfo';

type S3DisplayFileInfo = {
  name: string; // ファイル名
  ext: string; // ファイル拡張子
  size?: number; // フォーマットされたサイズ
}

const MeetingFileIcon = observer(({extension}: {
  extension: string,
}) => {
  // 小文字に変換して照合
  const ext = extension.toLowerCase();
  let iconName = 'file-earmark';  // デフォルトのアイコン

  switch (ext) {
    // 文書
    case 'pdf':
      iconName = 'file-earmark-pdf-fill';
      break;
    case 'doc':
    case 'docx':
      iconName = 'file-earmark-word-fill';
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
      iconName = 'file-earmark-spreadsheet-fill';
      break;
    case 'ppt':
    case 'pptx':
      iconName = 'file-earmark-ppt-fill';
      break;
    case 'txt':
    case 'rtf':
      iconName = 'file-earmark-text-fill';
      break;

    // 画像
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      iconName = 'file-earmark-image-fill';
      break;

    // 音声・動画
    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'aac':
      iconName = 'file-earmark-music-fill';
      break;
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
      iconName = 'file-earmark-play-fill';
      break;

    // アーカイブ
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
      iconName = 'file-earmark-zip-fill';
      break;

    // コード
    case 'html':
    case 'htm':
    case 'css':
    case 'js':
    case 'jsx':
    case 'ts':
    case 'tsx':
    case 'json':
    case 'xml':
    case 'yaml':
    case 'yml':
      iconName = 'file-earmark-code-fill';
      break;
  }

  return <i className={`bi bi-${iconName}`} />;
});

export const MeetingFileList = observer(({ meetingId }: {
  meetingId: string,
}) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [files, setFiles] = useState<S3DisplayFileInfo[]>([]);

  useEffect(() => {
    (async() => {
      try {
        const response = await getRoomFileList(meetingId);
        if (response.data && response.data.success && response.data.files) {
          console.log(response.data.files)
          setFiles(response.data.files as S3DisplayFileInfo[]);
          setLoaded(true);
        }
      } catch (e) {
        console.error(e);
        setFiles([]);
        setLoaded(false);
        alert(t('エラーが発生しました'));
      }
    })();
  }, [meetingId, t]);

  const handleDownloadSingleFile = useCallback(async (name: string) => {
    try {
      const response = await getRoomFile(meetingId, name);
      if (!response.data) {
        alert(t('エラーが発生しました'));
        return;
      }
      saveAs(new Blob([response.data]), name);
    } catch (e) {
      console.error(e);
      alert(t('エラーが発生しました'));
    }
  }, [meetingId, t]);

  const handleDeleteFile = useCallback(async (name: string) => {
    try {
      if (!window.confirm(t('ファイルを削除します。よろしいですか？'))) {
        return;
      }
      const response = await deleteRoomFile(meetingId, name);
      if (!response.data || !response.data.success || !response.data.name || response.data.name === '') {
        alert(t('エラーが発生しました'));
        return;
      }
      setFiles(prevState => {
        const newFiles = prevState.filter(f => f.name !== response.data.name);
        if (newFiles.length === 0) {
          (async() => {
            await updateMeetingHasUploadedFiles(UserInfo.id, meetingId, false);
          })();
        }
        return [...newFiles];
      });
    } catch (e) {
      console.error(e);
      alert(t('エラーが発生しました'));
    }
  }, [meetingId, t]);

  const handleDownloadFiles = useCallback(async () => {
    try {
      const response = await getRoomFiles(meetingId);
      const data = response.data;
      const blob = new Blob([data], { type: 'application/zip' });
      const uri = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = `files.zip`;
      link.href = uri;
      link.click();
    } catch (e) {
      console.error(e);
      alert(t('ファイルをダウンロードできませんでした。ファイルが存在しないか、アクセス過多の可能性があります。しばらく経ってから再度実行してください。'))
    }
  }, [meetingId, t]);

  return loaded ? (
    files && files.length !== 0 ? (
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
              <th style={{width: '50%'}}>{t('ファイル名')}</th>
              <th style={{width: '20%'}}>{t('サイズ')}</th>
              <th style={{width: '30%', minWidth: '13em'}}>{t('操作')}</th>
            </tr>
            </thead>
            <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td className="align-middle"><MeetingFileIcon extension={file.ext} /> {file.name}</td>
                <td className="align-middle">{file.size ? formatFileSize(file.size) : '-'}</td>
                <td className="align-middle">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleDownloadSingleFile(file.name)}
                    >{t('ダウンロード')}</button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteFile(file.name)}
                    >{t('削除')}</button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer pt-0 pb-0 border-0">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => hide()}>
            {t('閉じる')}
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleDownloadFiles}>
            {t('一括ダウンロード')}
          </button>
        </div>
      </div>
    ) : (
      <>{t('ファイルは見つかりませんでした')}</>
    )
  ) : (
    <>{t('ファイル一覧の取得中...')}</>
  );
});
