import './Menu.css'

import { Route, useHistory, useParams } from 'react-router-dom'
import { useEffect, type DetailedHTMLProps, type HTMLAttributes, useCallback, useState, useMemo } from 'react'

import UserInfo from '../../store/UserInfo'
import { Avatar } from '../../view/Avatar'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

const RouteMatch = ({ onMatch }: { onMatch?: (route?: string) => void }) => {
  const { page } = useParams() as any

  useEffect(() => {
    onMatch && onMatch(page)
  }, [onMatch, page])

  return null
}

type MenuItemProps = {
  icon?: string
  text: string
  active?: boolean
  page?: string
}

const MenuItem = ({ icon, text, active, page }: MenuItemProps) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    if (page !== undefined) {
      history.push(`/${page}`)
    }
  }, [history, page])

  return (
    <div className={`menu-item text-donut-dark ${active ? ' active' : ''}${page === undefined ? ' no-mouse' : ''} d-flex align-items-center ps-3${icon ? ' mt-5' : ' mt-2'}`}
      onClick={handleClick}>
      <i className={`bi bi-${icon || 'chevron-right'} ${icon ? 'me-3 h2 mb-0' : 'small me-2 ms-3'}`} />
      <span className={icon ? undefined : 'small'}>{text}</span>
      <span className={`active-bar ms-3 rounded-start${active ? ' bg-secondary' : ''}`} />
    </div>
  )
}

export const Menu = observer((props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [page, setPage] = useState<string | undefined>(undefined)
  const { t } = useTranslation()
  const history = useHistory()

  const MENU_ITEMS = useMemo(() => {
    return [
      ...[{
        root: true,
        icon: 'file-earmark-fill',
        text: t('議事録一覧'),
        page: '',
        active: ['share'],
      }],
      ...(process.env.REACT_APP_ENVIRONMENT !== 'production' ? [{
        icon: 'chat-dots-fill',
        text: `${t('AIアドバイザー')}(${t('β版')})`,
        page: 'advisor',
        active: ['advisor'],
      }] : []),
      ...[{
        icon: 'people-fill',
        text: t('メンバー一覧'),
        page: 'members',
        active: ['members'],
      }, {
        icon: 'gear-fill',
        text: t('各種設定'),
        page: 'settings',
        active: ['settings'],
      }],
      ...(UserInfo.isAdmin ? [{
        icon: 'briefcase-fill',
        text: t('管理者専用ページ'),
        page: 'admin',
      }] : []),
      ...[{
        icon: 'question-circle-fill',
        text: t('使い方 / お問い合わせ'),
      }, {
        text: t('ユーザーマニュアル'),
        page: 'guide',
        active: ['guide'],
      }, {
        text: t('お問い合わせ'),
        page: 'contact',
        active: ['contact'],
      }, {
        text: t('よくある質問'),
        page: 'questions',
        active: ['questions'],
      }]
    ]
  }, [t])

  const handleRouteChange = useCallback((page?: string) => {
    setPage(page)
  }, [])

  const handleLogOut = () => {
    history.push('/')
    UserInfo.logOut()
  }

  const isManager = UserInfo.isManager ?? false
  const menuItems = MENU_ITEMS.filter(x => x.page !== 'members' || isManager)

  return (
    <div className='menu-content' {...props}>
      <Route path='/:page?'>
        <RouteMatch onMatch={handleRouteChange} />
      </Route>
      <div className='user-info py-5 d-flex flex-column align-items-center'>
        <Avatar />
        <div className='text-link mt-3' onClick={handleLogOut}>
          <span>{t('ログアウト')}</span>
        </div>
      </div>
      <div className='navigation'>
        {menuItems.map((menu, index) => {
          return (
            <MenuItem
              key={index}
              icon={menu.icon}
              text={menu.text}
              page={menu.page}
              active={(!!page && !!menu.active && menu.active.includes(page)) || (!page && !!menu.root)} />
          )
        })}
      </div>
      <div className='padding'>
        <div />
        <div />
      </div>
    </div>
  )
})
