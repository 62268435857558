import './MeetingTaggedSearchInput.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Meeting } from '../../store/model/Meeting';
import UserInfo from '../../store/UserInfo';

interface TaggedSearchInputProps {
  /** フィルタリング対象のミーティング配列 */
  meetings: Meeting[];
  /** フィルタリング中を表すコールバック */
  onSearching: (searching: boolean) => void;
  /** フィルタリング結果を返すコールバック */
  onFilteredMeetingsChange: (meetings: Meeting[]) => void;
  /** 入力結果を返すコールバック */
  onKeywordsChange: (keywords: string[]) => void;
  /** タグの情報を返すコールバック */
  onTagsChange?: (allTags: string[]) => void;
  /** ソートのフィールド名 */
  sortField: string;
  /** ソートの方向 */
  sortDirection: 'asc' | 'desc';
  /** 検索入力欄のプレースホルダーテキスト */
  placeholder?: string;
  /** コンポーネントに追加するCSSクラス名 */
  className?: string;
}

export const KEYWORD_SPLIT_PATTERN = /[ 　\n\r]+/;

export const splitKeywords = (keyword: string): string[] => {
  return keyword.trim() !== '' ? keyword.split(KEYWORD_SPLIT_PATTERN) : [];
};

const MeetingTaggedSearchInput: React.FC<TaggedSearchInputProps> = ({
  meetings,
  onSearching,
  onFilteredMeetingsChange,
  onKeywordsChange,
  onTagsChange,
  sortField = 'state',
  sortDirection = 'asc',
  placeholder = '',
  className = ''
}) => {
  const [keyword, setKeyword] = useState('');
  const [searchInputFocus, setSearchInputFocus] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [unSelectedTags, setUnSelectedTags] = useState<string[]>([]);

  const searchRef = useRef<HTMLInputElement>(null);
  const searchInputFocusRef = useRef(searchInputFocus);

  // フィルタリングロジック
  const filterMeetings = useCallback(() => {
    onSearching(true);
    const keywords = splitKeywords(keyword);
    if (!UserInfo.meetings) return;

    const filtered = UserInfo.meetings.filterAndSort({
      keyword: keywords.join(' '),
      tags: selectedTags,
      sortField: sortField,
      sortDirection: sortDirection
    });

    onFilteredMeetingsChange(filtered);
    onSearching(false);
  }, [onSearching, keyword, selectedTags, sortField, sortDirection, onFilteredMeetingsChange]);

  // タグの初期設定
  useEffect(() => {
    if (meetings.length > 0) {
      filterMeetings();
      const allFullTags = meetings.map(m => m.tags);
      const flatAllFullTags = allFullTags.flat();
      const uniqueAllFullTagsMap = new Map(flatAllFullTags.map(item => [item, item]));
      const uniqueAllFullTags = Array.from(uniqueAllFullTagsMap.values());
      setAllTags(uniqueAllFullTags);
      onTagsChange?.(uniqueAllFullTags);
    }
  }, [filterMeetings, meetings, onTagsChange]);

  // フィルター条件が変更されたら実行
  useEffect(() => {
    filterMeetings();
  }, [filterMeetings]);

  // 以下、UI関連のロジック
  useEffect(() => {
    searchInputFocusRef.current = searchInputFocus;
  }, [searchInputFocus]);

  const showTagsDropDown = useCallback(() => {
    if (searchRef.current) {
      const dropdown = new (window as any).bootstrap.Dropdown(searchRef.current);
      dropdown.show();
    }
  }, []);

  const hideTagsDropDown = useCallback(() => {
    if (searchRef.current) {
      const dropdown = new (window as any).bootstrap.Dropdown(searchRef.current);
      dropdown.hide();
    }
  }, []);

  const handleDropDownTagClick = useCallback((tag: string) => {
    setSelectedTags(prev => [...prev, tag]);
    setKeyword('');
  }, []);

  const handleSearchedTagClick = useCallback((tag: string) => {
    setSelectedTags(prev => prev.filter(t => !t.includes(tag)));
    showTagsDropDown();
  }, [showTagsDropDown]);

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKeyword(value);
    setTimeout(() => {
      const newKeywords = value.trim() !== '' ? value.split(KEYWORD_SPLIT_PATTERN) : []; // 最新の value を使用
      onKeywordsChange(newKeywords);
      showTagsDropDown();
    }, 1)
  }, [onKeywordsChange, showTagsDropDown]);

  const handleSearchInputFocus = useCallback(() => {
    setSearchInputFocus(true);
  }, []);

  const handleSearchInputBlur = useCallback(() => {
    setSearchInputFocus(false);
    setTimeout(() => {
      !searchInputFocusRef.current && hideTagsDropDown();
    }, 500);
  }, [hideTagsDropDown]);

  useEffect(() => {
    let unSelected = allTags.filter(t => !selectedTags.includes(t));
    if (keyword !== '') {
      unSelected = unSelected.filter(t =>
        splitKeywords(keyword).some(kw => t.includes(kw))
      );
    }
    setUnSelectedTags(unSelected);
  }, [allTags, keyword, onKeywordsChange, selectedTags, showTagsDropDown]);

  return (
    <div className={`search input-group dropdown ${className}`}>
      <span className="input-group-text">
        <i className="bi bi-search"></i>
      </span>
      <div
        className="tags-and-input d-flex flex-wrap"
        onClick={() => searchRef.current?.focus()}
        data-bs-toggle="dropdown"
      >
        <div className="tags">
          {selectedTags.map(tag => (
            <div
              key={tag}
              className="tag badge badge-pill bg-primary"
            >
              <i className="bi bi-tag-fill"></i>
              {tag}
              <i
                className="close bi bi-x-circle-fill"
                onClick={() => handleSearchedTagClick(tag)}
              ></i>
            </div>
          ))}
        </div>
        <input
          id="search-form"
          ref={searchRef}
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={keyword}
          onChange={handleSearchInputChange}
          onFocus={handleSearchInputFocus}
          onBlur={handleSearchInputBlur}
        />
        <ul className="dropdown-menu">
          {unSelectedTags.map(tag => (
            <li key={tag}>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => handleDropDownTagClick(tag)}
              >
                {tag}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MeetingTaggedSearchInput;
