import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { saveAs } from 'file-saver'

import { SummaryTask } from '../../api/UserSummaryApi'
import { BUTTON_MODES, show } from '../../view/PopupEvent'
import LoadingButton from '../../view/LoadingButton'
import { ADMIN_GET_USER_ACTION_LOG } from '../../constant/Endpoints'

export const AdminPage = observer(() => {
  const [summaryFetching, setSummaryFetching] = useState(false)
  const [userLogFetching, setUserLogFetching] = useState(false)

  const fetchUserSummary = async () => {
    setSummaryFetching(true)
    try {
      const data = await new Promise<string>((resolve, reject) => {
        const task = new SummaryTask()
        task.start((data) => {
          if (data) {
            resolve(data)
          } else {
            reject()
          }
        })
      })
      
      // save to file
      const blob = new Blob([data], { type: 'text/plain' })
      saveAs(blob, `user_summary_${new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })}.csv`)
    } catch (e) {
      show({
        title: 'エラー',
        content: 'データ取得に失敗しました',
        btnMode: BUTTON_MODES.OK,
      })
    }
    setSummaryFetching(false)
  }

  const fetchUserActionLog = async () => {
    setUserLogFetching(true)
    try {
      const data = await new Promise<string>((resolve, reject) => {
        const task = new SummaryTask(ADMIN_GET_USER_ACTION_LOG)
        task.start((data) => {
          if (data) {
            resolve(data)
          } else {
            reject()
          }
        })
      })
      
      // save to file
      const blob = new Blob([data], { type: 'text/plain' })
      saveAs(blob, `user_action_log_${new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })}.csv`)
    } catch (e) {
      show({
        title: 'エラー',
        content: 'データ取得に失敗しました',
        btnMode: BUTTON_MODES.OK,
      })
    }
    setUserLogFetching(false)
  }

  return (
    <div className='container p-4 p-md-4 text-donut'>
      <div className='row mb-3'>
        <div className='col-auto h1 text-primary mb-0'>
          <b>{('管理者専用')}</b>
        </div>
        <div className='col text-donut'>
          {('管理者専用ページです。')}
        </div>
      </div>

      <div className='row'>
        <div className='col-auto'>
          <LoadingButton buttonStyle='primary' onClick={fetchUserSummary} isLoading={summaryFetching} disabled={userLogFetching}>
            {summaryFetching ? '処理中...' : '会員登録データを抽出'}
          </LoadingButton>
        </div>

        <div className='col-auto'>
          <LoadingButton buttonStyle='primary' onClick={fetchUserActionLog} isLoading={userLogFetching} disabled={summaryFetching}>
            {userLogFetching ? '処理中...' : 'ユーザーのアクションログを抽出'}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
})
