import {
  MEETING_BEGIN_URL,
  MEETING_CREATE_FROM_AUDIO_DATA,
  MEETING_CREATE_URL,
  MEETING_FINISH_URL,
  MEETING_UPDATE_SUMMARY_MINUTES,
  MEETING_ROOM_FILES,
  MEETING_DELETE_ROOM,
  MEETING_GET_SHARE_ROOM,
  MEETING_UPDATE_SHARE_ROOM,
} from '../constant/Endpoints';
import Api from './Api'
import i18next from "i18next";
import { AxiosProgressEvent } from 'axios';
import { SHARE_SCOPE_TYPE } from './MeetingFirebaseApi';


/**
 * ミーティングURLを作成する
 * @param title - ミーティングタイトル
 * @param scheduledBeginAt - 開始予定日時
 * @param scheduledEndAt - 終了予定日時
 * @param allocatedTime - ミーティング時間
 * @param enableAIAdvisor - AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
 * @param includeMeetingIds - 会議AIアシスタントに提供するミーティングのID
 * @param agendaFiles - アジェンダ資料
 * @param files - その他の会議資料
 * @param isDefaultTitle - 会議タイトルを編集していないかどうか（編集していない場合はAIが変更可能とする）
 */
export const createMeetingUrl = async (title: string, scheduledBeginAt: string, scheduledEndAt: string, allocatedTime: number, enableAIAdvisor: boolean, includeMeetingIds: string[], agendaFiles?: FileList | null | undefined, files?: FileList | null | undefined, isDefaultTitle = false) => {
  let additionalDocument = '';
  if(agendaFiles && agendaFiles.length !== 0){
    additionalDocument = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });
        // @ts-ignore
        if([...segmenter.segment(result)].length > 3000){
          reject(i18next.t('テキストファイルの文字数は3000文字以内にしてください'));
        }
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(agendaFiles[0]);
    });
  }

  const formData = new FormData();
  if(files && files.length !== 0){
    // ファイルの追加
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }
  formData.append('title', title);
  formData.append('scheduledBeginAt', scheduledBeginAt);
  formData.append('scheduledEndAt', scheduledEndAt);
  formData.append('allocatedTime', allocatedTime.toString());
  formData.append('enableAIAdvisor', enableAIAdvisor ? '1': '0');
  includeMeetingIds.forEach((includeMeetingId) => {
    formData.append('includeMeetingIds[]', includeMeetingId);
  });
  formData.append('additionalDocument', additionalDocument);
  formData.append('isDefaultTitle', isDefaultTitle ? '1': '0');
  formData.append('domain', process.env.REACT_APP_FIREBASE_PROJECTID!);

  return Api.post(MEETING_CREATE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * 音声ファイルからミーティングを作成する
 * @param title - ミーティングタイトル
 * @param languages - 使用言語
 * @param translateLanguage - 翻訳言語
 * @param scheduledBeginAt - 開始予定日時
 * @param enableAIAdvisor - AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
 * @param includeMeetingIds - 会議AIアシスタントに提供するミーティングのID
 * @param audioFiles - 音声ファイル
 * @param agendaFiles - アジェンダ資料
 * @param files - その他の会議資料
 * @param isDefaultTitle - 会議タイトルを編集していないかどうか（編集していない場合はAIが変更可能とする）
 * @param onUploadProgress - 進行状況を伝える関数
 */
export const createMeetingFromAudioData = async (title: string, languages: string[], translateLanguage: string, scheduledBeginAt: string, enableAIAdvisor: boolean, includeMeetingIds: string[], audioFiles: FileList, agendaFiles?: FileList | null | undefined, files?: FileList | null | undefined, isDefaultTitle: boolean = false, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
  let additionalDocument = '';
  if(agendaFiles && agendaFiles.length !== 0){
    additionalDocument = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });
        // @ts-ignore
        if([...segmenter.segment(result)].length > 3000){
          reject(i18next.t('テキストファイルの文字数は3000文字以内にしてください'));
        }
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(agendaFiles[0]);
    });
  }

  const formData = new FormData();
  if(files && files.length !== 0){
    // ファイルの追加
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }
  if(audioFiles && audioFiles.length !== 0){
    // ファイルの追加
    for (let i = 0; i < audioFiles.length; i++) {
      formData.append('audio', audioFiles[i]);
    }
  }
  formData.append('title', title);
  languages.forEach((language) => {
    formData.append('languages[]', language);
  });
  formData.append('translateLanguage', translateLanguage);
  formData.append('scheduledBeginAt', scheduledBeginAt);
  formData.append('enableAIAdvisor', enableAIAdvisor ? '1': '0');
  includeMeetingIds.forEach((includeMeetingId) => {
    formData.append('includeMeetingIds[]', includeMeetingId);
  });
  formData.append('additionalDocument', additionalDocument);
  formData.append('isDefaultTitle', isDefaultTitle ? '1': '0');
  formData.append('domain', process.env.REACT_APP_FIREBASE_PROJECTID!);

  return Api.post(MEETING_CREATE_FROM_AUDIO_DATA, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

export const finishMeeting = (meetingId: string) => {
  return Api.post(MEETING_FINISH_URL, { meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID })
}

export const beginMeeting = (meetingId: string) => {
  return Api.post(MEETING_BEGIN_URL, { meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID })
}

export const getSharedInfo = (token: string) => {
  return Api.get(`${MEETING_GET_SHARE_ROOM}/${token}`);
}

export const setSharedInfo = (roomId: string, expiry: number, scope: SHARE_SCOPE_TYPE, emails: string[]) => {
  return Api.post(`${MEETING_UPDATE_SHARE_ROOM}`, {roomId, expiry, scope, emails, domain: process.env.REACT_APP_FIREBASE_PROJECTID });
}

export const updateSummaryMinutes = (meetingId: string) => {
  return Api.post(MEETING_UPDATE_SUMMARY_MINUTES, { meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID })
}

export const getRoomFileList = (meetingId: string) => {
  return Api.get(`${MEETING_ROOM_FILES}/${meetingId}`);
}

export const deleteRoomFile = (meetingId: string, name: string) => {
  return Api.delete(`${MEETING_ROOM_FILES}/${meetingId}?name=${encodeURIComponent(name)}`);
}

export const getRoomFile = (meetingId: string, name: string) => {
  return Api.get(`${MEETING_ROOM_FILES}/${meetingId}/download?name=${encodeURIComponent(name)}`, {
    responseType: 'arraybuffer',
  })
}

export const getRoomFiles = (meetingId: string) => {
  return Api.get(`${MEETING_ROOM_FILES}/${meetingId}/download`, {
    responseType: 'arraybuffer',
    headers: { Accept: 'application/zip' },
  })
}

export const deleteRoom = (meetingId: string) => {
  return Api.delete(MEETING_DELETE_ROOM, { data: {meetingId, domain: process.env.REACT_APP_FIREBASE_PROJECTID } })
}
