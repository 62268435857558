import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { _parseQueryParam } from '../../util/Util'
import UserInfo from '../../store/UserInfo'
import { BUTTON_MODES, show } from '../../view/PopupEvent'
import { useTranslation } from 'react-i18next'
import { sentryLog } from "../../util/sentry";

export const EmailConfirm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [confirmed, setConfirmed] = useState<boolean | undefined>(undefined)

  const token = useMemo(() => {
    const token = _parseQueryParam('token', false)
    return Array.isArray(token) ? token[0] : token
  }, [])

  const plan = useMemo(() => {
    const _plan = _parseQueryParam('plan', false)
    return Array.isArray(_plan) ? _plan[0] : _plan
  }, [])

  useEffect(() => {
    if (token) {
      UserInfo.confirmEmail(token).then(async () => {
        // send custom event to GA
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          (window as any).dataLayer?.push({ event: 'registeredpopupshown' })
        }
        await show({
          content: t('メールの認証に成功しました。'),
          btnMode: BUTTON_MODES.OK,
          staticBackdrop: false,
        })
        if (plan) {
          history.push(`/subscribe?plan=${plan}`)
        } else {
          history.push('/')
        }
      }).catch((error) => {
        // log to sentry
        sentryLog(error)
        show({
          content: error.response?.data?.message || error.message || `${error}`,
          btnMode: BUTTON_MODES.OK,
          staticBackdrop: false,
        })
        setConfirmed(false)
      })
    } else {
      // no token => nothing to confirm
      // => redirect to home page instead of being stuck on this page
      history.push('/')
    }
  }, [history, token, t, plan])

  return (
    <div className='pt-5' style={{ width: '100%', textAlign: 'center' }}>
      {confirmed === undefined ? (
        <span>{t('メールを認証中…')}</span>
      ) : (
        !confirmed && (
          <span className='text-danger'>
            {t('メール認証に失敗しました。')}
          </span>
        )
      )}
    </div>
  )
}
