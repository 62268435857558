import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

type PasswordInputProps = {
  id?: string
  passwordRef: RefObject<HTMLInputElement>
}

export const PasswordInput = ({ id, passwordRef }: PasswordInputProps) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <div className='d-flex align-items-center'>
      <input type={show ? 'text' : 'password'} className='form-control' id={id || 'signin-password'}
        ref={passwordRef} placeholder={t('パスワード')} />
      <i className={`ms-2 h4 mb-0 bi bi-${show ? 'eye-fill text-primary' : 'eye-slash text-secondary'}`}
        onClick={() => setShow((s) => !s)}/>
    </div>
  )
}
