import './MeetingItem.css';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSound from 'use-sound';
import saveAs from 'file-saver';

import MyTippy from '../../view/MyTippy';
import CopyToClipboard from '../../view/CopyToClipboard';
import { useCopyToClipboard } from '../../hook/useCopyToClipboard';
import {
  getMeetingMessages, isExistMeetingMessage,
  MEETING_STATES,
  MEETING_STATES_TYPE,
  NotificationFirebaseData,
  readNotifications,
  SHARE_SCOPE_TYPE,
  updateMeeting,
} from '../../api/MeetingFirebaseApi';
import UserInfo from '../../store/UserInfo';
import {
  MINUTE,
  initialMinutes,
  initialSummary,
  Meeting,
  Speaker,
  summaryAgenda,
  SummaryStatus,
  DisplayChatMessage,
  ChatMessage,
} from '../../store/model/Meeting';
import LoadingButton from '../../view/LoadingButton';
import {
  beginMeeting,
  deleteRoom,
  finishMeeting,
  setSharedInfo,
  updateSummaryMinutes,
} from '../../api/MeetingApi';
import { ILanguages } from '../../constant/Languages';
import { BUTTON_MODES, hide, hideAll, show, SIZE_MODE } from '../../view/PopupEvent';
import { bootstrapQuery, useMediaQuery } from '../../hook/useMediaQuery';
import { className } from '../../util/className';
import { convertDateTimeToString, convertDateTimeToStringForView, getNextWeekDate } from '../../util/date';
import { useTranslation } from 'react-i18next';

import RemoveMarkdown from 'remove-markdown';
import { EditMessage } from './EditMessage';
import { sentryLog } from '../../util/sentry';
import { CreateEvent, DispatchEvent, STOP_AUDIO_EVENT } from '../../util/eventListener';
import { useMinutes } from '../../hook/useMinutes';
import { copyToClipboardWithRetryAndFallback, isValidEmail } from '../../util/Util';
import { DisplayLayout } from './MeetingList';
import { EditTag } from './EditTag';
import { titleMaxLength } from '../../constant/Variables';
import Highlight from '../../view/Highlight';
import { MeetingFileList } from './MeetingFileList';

const shareIconElements: { [shareScopeType in SHARE_SCOPE_TYPE]: React.ReactNode } = {
  [SHARE_SCOPE_TYPE.public]: <i className="bi bi-link-45deg"></i>,
  [SHARE_SCOPE_TYPE.members]: <i className="bi bi-people-fill"></i>,
  [SHARE_SCOPE_TYPE.email]: <i className="bi bi-envelope-at-fill"></i>,
  [SHARE_SCOPE_TYPE.private]: <i className="bi bi-lock-fill"></i>,
};

const defaultExpiry: number = getNextWeekDate().getTime();

const stateViewStr: Record<MEETING_STATES_TYPE, {color: string, i18n: string}> = {
  [MEETING_STATES.BUILDING]: {color: 'warning', i18n: '作成中'},
  [MEETING_STATES.WAITING]: {color: 'primary', i18n: '未開始'},
  [MEETING_STATES.INCALL]: {color: 'danger', i18n: '会議中'},
  [MEETING_STATES.FINISH]: {color: 'warning', i18n: '作成中'},
  [MEETING_STATES.COMPLETED]: {color: 'success', i18n: '完了'},
}

const MeetingUserNameConfigureBody = observer((
  {
    speakers,
    userId,
    speakerId,
    userName,
    enableCustomUserName,
    customUserName,
    updateSpeaker,
    updateCustomUserName,
    onAllUpdate,
  }: {
    speakers: Speaker[],
    userId: string,
    speakerId: string,
    userName: string,
    enableCustomUserName: boolean,
    customUserName: string,
    updateSpeaker: (id: string, userName: string) => Promise<void>,
    updateCustomUserName: (userName: string, enabled: boolean, speakerId: string) => Promise<void>,
    onAllUpdate: () => void,
  }) => {
  const { t } = useTranslation()

  const [inputSpeakers, setInputSpeakers] = useState<(Speaker & {isChecked: boolean})[]>([]);
  const [inputCustomUserName, setInputCustomUserName] = useState<string>(customUserName);

  const isChecked = useCallback((id: string) => {
    if (enableCustomUserName) {
      return false;
    }
    if (id === `${userId}/${speakerId}`) {
      return true;
    }
    return speakerId.split('/').length > 1 && id === `${userId}/${speakerId.split('/')[1]}`;

  }, [enableCustomUserName, speakerId, userId]);

  useEffect(() => {
    const filtered = speakers.filter(s => s.id.split('/')[0] === userId);
    const addChecked = filtered.map(f => {
      return {...f, isChecked: isChecked(f.id)};
    })
    setInputSpeakers([...addChecked]);
  }, [isChecked, speakers, userId]);

  const handleCheckSpeakersChange = useCallback((id: string) => {
    setInputSpeakers(prevState => {
      return prevState.map(s => {return {...s, isChecked: s.id === id}});
    })
  }, []);

  const handleCheckCustomSpeakersChange = useCallback(() => {
    setInputSpeakers(prevState => {
      return prevState.map(s => {return {...s, isChecked: false}});
    })
  }, []);

  const handleInputSpeakersChange = useCallback((index: number, value: string) => {
    setInputSpeakers(prevState => {
      const newInputSpeakers = [...prevState];
      newInputSpeakers[index].username = value;
      return newInputSpeakers;
    })
  }, []);

  const updateUserNameConfigure = useCallback(async () => {
    if (inputSpeakers.findIndex(s => s.username === '') !== -1) {
      alert(t('未入力の話者名があります'))
      return;
    }
    if (inputSpeakers.findIndex(s => s.isChecked) === -1 && inputCustomUserName === '') {
      alert(t('直接指定の話者名が未入力です'))
      return;
    }

    let custom = true;
    let newSpeakerId = speakerId;
    for (const inputSpeaker of inputSpeakers) {
      const index = speakers.findIndex(s => s.id === inputSpeaker.id);
      if (index !== -1) {
        const isChanged = speakers[index].username !== inputSpeaker.username;
        if (isChanged) {
          await updateSpeaker(inputSpeaker.id, inputSpeaker.username ?? `${inputSpeaker.id.split('/')[1]}`);
        }
      }
      if (inputSpeaker.isChecked) {
        custom = false;
        newSpeakerId = inputSpeaker.id.split('/')[1];
      }
    }
    await updateCustomUserName(inputCustomUserName, custom, newSpeakerId);
    onAllUpdate();
    hide();
  }, [inputSpeakers, inputCustomUserName, speakerId, updateCustomUserName, onAllUpdate, t, speakers, updateSpeaker]);

  return (
    <form
      className="row g-3 user-name-form"
      onSubmit={updateUserNameConfigure}
    >
      {inputSpeakers.map((speaker, index) => (
        <div key={speaker.id} className="input-group">
          <label className="input-group-text">
            <input
              className="form-check-input mt-0"
              type="radio"
              checked={speaker.isChecked}
              onChange={() => handleCheckSpeakersChange(speaker.id)}
            />
          </label>
          <div className="input-group-text user-id">{speaker.id.split('/')[1]}</div>
          <input
            type="text"
            className="form-control"
            disabled={!speaker.isChecked}
            value={speaker.username ?? `${speaker.id.split('/')[1]}`}
            onChange={e => handleInputSpeakersChange(index, e.currentTarget.value)}
          />
        </div>
      ))}
      <div className="input-group">
        <label className="input-group-text">
          <input
            className="form-check-input mt-0"
            type="radio"
            checked={inputSpeakers.findIndex(s => s.isChecked) === -1}
            onChange={() => handleCheckCustomSpeakersChange()}
          />
        </label>
        <div className="input-group-text">{t('直接指定')}</div>
        <input
          type="text"
          className="form-control"
          value={inputCustomUserName}
          disabled={inputSpeakers.findIndex(s => s.isChecked) !== -1}
          onChange={e => setInputCustomUserName(e.currentTarget.value)}
        />
      </div>
      <hr />
      <div className="modal-footer pb-0">
        <button type="button" className="btn btn-secondary" onClick={() => hide()}>
          {t('閉じる')}
        </button>
        <button type="button" className="btn btn-primary"
                onClick={() => updateUserNameConfigure()}>
          {t('名前を更新')}
        </button>
      </div>
    </form>
  );
});

const MeetingShareConfigureBody = observer(({ meeting }: { meeting: Meeting }) => {
  const { t } = useTranslation();

  const [expiry, setExpiry] = useState<number>(meeting.shareInfo && meeting.shareInfo.expiry ? meeting.shareInfo.expiry : defaultExpiry);
  const [expiryDateFormat, setExpiryDateFormat] = useState<string>(convertDateTimeToString(new Date(expiry)));
  const [scope, setScope] = useState<SHARE_SCOPE_TYPE>(meeting.shareInfo ? meeting.shareInfo.scope : SHARE_SCOPE_TYPE.private);
  const [emails, setEmails] = useState<string[]>(meeting.shareInfo && meeting.shareInfo.emails.length !== 0 ? meeting.shareInfo.emails : ['']);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    setExpiry(meeting.shareInfo && meeting.shareInfo.expiry ? meeting.shareInfo.expiry : defaultExpiry);
    setScope(meeting.shareInfo ? meeting.shareInfo.scope : SHARE_SCOPE_TYPE.private);
    setEmails(meeting.shareInfo && meeting.shareInfo.emails.length !== 0 ? meeting.shareInfo.emails : ['']);
  }, [meeting.shareInfo]);

  useEffect(() => {
    setExpiryDateFormat(convertDateTimeToString(new Date(expiry)));
  }, [expiry]);

  const handleAddEmail = useCallback(() => {
    setEmails(prevState => [...prevState, '']);
  }, []);

  const handleResetEmail = useCallback(() => {
    setEmails(['']);
  }, []);

  const updateEmails = useCallback((index: number, value: string) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  }, [emails]);

  const validateShareConfigure = useCallback(() => {
    const errors = [];

    // 有効期限のチェック
    if (!expiry) {
      errors.push(t('有効期限は必須です'));
    } else if (expiry <= Date.now()) {
      errors.push(t('有効期限には未来の日時を設定してください'));
    } else {
      try {
        new Date(expiry);
      } catch (e) {
        console.error(e);
        errors.push(t('有効期限を正しく入力してください'));
      }
    }

    // 公開範囲のチェック
    if (!Object.values(SHARE_SCOPE_TYPE).includes(scope)) {
      errors.push(t('公開範囲を正しく入力してください'));
    }

    // メールアドレスのチェック
    if (emails && emails.length !== 0) {
      const noBlankEmails = emails.filter(e => e.trim() !== '');
      const invalidEmails = noBlankEmails.filter(e => !isValidEmail(e));
      if (invalidEmails.length !== 0) {
        errors.push(`${t('メールアドレスを正しく入力してください')}: ${invalidEmails.join(', ')}`);
      }
    }

    // エラーがある場合は表示
    if (errors.length !== 0) {
      alert(errors.join('\n'));
      return false;
    }

    return true;
  }, [emails, expiry, scope, t]);

  const updateShareConfigure = useCallback(async () => {
    try {
      // 多重クリックブロック
      setIsButtonDisabled(true);

      // バリデーションチェック
      if (!validateShareConfigure()) {
        // 多重クリックブロック解除
        setIsButtonDisabled(false);
        return;
      }

      // 共有設定を更新
      const noBlankEmails = emails.filter(e => e.trim() !== '');
      const response = await setSharedInfo(meeting.id, expiry, scope, noBlankEmails);
      //console.log(response);
      if (!response.data.success || !response.data.token) {
        if (response.data.message) {
          alert(t(response.data.message));
        }
        // 多重クリックブロック解除
        setIsButtonDisabled(false);
        return;
      }

      // 非公開の場合はここで終了
      if (scope === SHARE_SCOPE_TYPE.private) {
        hide();
        return;
      }

      // 共有URL
      const url = `https://${process.env.REACT_APP_AUTHDOMAIN}/share/${response.data.token}`;
      //console.log(url);

      // コピー
      try {
        await copyToClipboardWithRetryAndFallback(url);
      } catch (e) {
        alert(t('共有リンクをコピーできませんでした。もう一度お試しください。'));
      }
      setIsCopied(true);
      setTimeout(() => {
        // モーダルを閉じる
        hide();
      }, 2000);
    } catch (e) {
      console.error(e);
      alert(t('共有設定を更新中にエラーが発生しました'));
      // 多重クリックブロック解除
      setIsButtonDisabled(false);
    }
  }, [emails, expiry, meeting.id, scope, t, validateShareConfigure]);

  return (
    <form
      className="row g-3"
      onSubmit={updateShareConfigure}
    >
      <div className="col-12">
        <label htmlFor="shareExpiry" className="form-label">{t('有効期限')}</label>
        <input
          type="datetime-local"
          className="form-control"
          id="shareExpiry"
          value={expiryDateFormat}
          onChange={(e) => {
            setExpiryDateFormat(e.currentTarget.value);
            setExpiry(new Date(e.currentTarget.value).getTime());
          }}
          min={convertDateTimeToString(new Date())}
        />
      </div>
      <div className="col-12">
        <label htmlFor="shareScope" className="form-label">{t('公開範囲')}</label>
        <select
          id="shareScope"
          className="form-select"
          value={scope}
          onChange={(e) => setScope(e.currentTarget.value as SHARE_SCOPE_TYPE)}>
          <option value={SHARE_SCOPE_TYPE.private}>{t('非公開')}</option>
          <option value={SHARE_SCOPE_TYPE.members}>{t('メンバーのみに公開')}</option>
          <option value={SHARE_SCOPE_TYPE.email}>{t('メールアドレス指定')}</option>
          <option value={SHARE_SCOPE_TYPE.public}>{t('全員に公開')}</option>
        </select>
      </div>
      {scope === SHARE_SCOPE_TYPE.email && (
        <div className="col-12">
          <div className="form-label">{t('メールアドレス')}</div>
          {emails.map((value, index) => (
            <input
              key={index}
              type="email"
              className="form-control form-control-sm mb-2"
              placeholder={t('メールアドレスを入力...')}
              onChange={(e) => updateEmails(index, e.currentTarget.value)}
              value={value} />
          ))}
          <button
            type="button"
            className="btn btn-sm btn-success d-block w-100 mb-1"
            onClick={handleAddEmail} disabled={emails.includes('')}>
            {t('追加')}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary d-block w-100"
            onClick={handleResetEmail} disabled={emails.length === 1 && emails[0] === ''}>
            {t('メールアドレスをクリア')}
          </button>
        </div>
      )}
      <hr />
      <div className="modal-footer pb-0">
        <button type="button" className="btn btn-secondary" onClick={() => hide()}>
          {t('閉じる')}
        </button>
        <button type="button" className="btn btn-primary" disabled={isButtonDisabled} onClick={() => updateShareConfigure()}>
          {scope === SHARE_SCOPE_TYPE.private ? t('非公開に設定') : isCopied ? t('コピーしました') : t('リンクをコピー')}
        </button>
      </div>
    </form>
  );
});

const MeetingShareButtonBody = observer(({ meeting }: { meeting: Meeting }) => {
  const { t } = useTranslation();
  const [shareIconElement, setShareIconElement] = useState<React.ReactNode>(shareIconElements.private);

  useEffect(() => {
    if (meeting.shareInfo) {
      setShareIconElement(shareIconElements[meeting.shareInfo.scope]);
    }
  }, [meeting.shareInfo]);

  const handleShareClick = useCallback(async () => {
    await show({
      title: t('共有設定'),
      content: <MeetingShareConfigureBody meeting={meeting} />,
      btnMode: BUTTON_MODES.NONE,
    });
  }, [meeting, t]);

  return (
    <button
      onClick={handleShareClick}
      className="btn btn-success rounded-pill share-btn"
    >
      {shareIconElement}
      <small className="d-none d-md-inline">{t('共有')}</small>
    </button>
  );
});

const MeetingTitleInput = observer(({ meeting, keywords, isShare }: { meeting: Meeting, keywords: string[], isShare: boolean }) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [currentTitle, setCurrentTitle] = useState<string>(meeting.title ?? '無題');

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTitle(event.target.value);
  };

  const updateTitle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget.checkValidity()) {
      // Intentionally made it callback to make sure
      // It won't get batched.
      setEditable(_curr => {
        if (meeting.title !== currentTitle) {
          const data = { title: currentTitle };

          updateMeeting(UserInfo.id, meeting.id, data).then(_result => {
            meeting.setData(data);
          });
        }

        return false;
      });
    }
  };

  const activateEdit = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setEditable(true);
    setCurrentTitle(meeting.title);
  }, [meeting.title]);

  const deactivateEdit = useCallback(() => {
    setEditable(false);
    setCurrentTitle(meeting.title);
  }, [meeting.title]);

  useEffect(() => {
    if (inputRef.current && editable) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editable]);

  return (
    <form
      className="d-flex flex-nowrap gap-2 align-items-center"
      onSubmit={updateTitle}
      onReset={deactivateEdit}
    >
      {editable
        ? (
          <>
            <input
              ref={inputRef}
              required
              maxLength={titleMaxLength}
              readOnly={!editable}
              className="form-control-plaintext flex-grow-1"
              style={{letterSpacing: '0.015em', fontSize: '24px', fontWeight: '400'}}
              value={currentTitle}
              onChange={onChangeTitle}
            />
            <button type="submit" className="btn badge rounded-pill text-success">
              <i className="fa-fw fa-solid fa-check" />
            </button>
            <button
              type="reset" className="btn badge rounded-pill text-danger"
            >
              <i className="fa-fw fa-solid fa-close" />
            </button>
          </>
        )
        : (
          <>
            <span
              className="form-control-plaintext flex-grow-1 hide-scroll"
              style={{letterSpacing: '0.015em', fontSize: '24px', fontWeight: '400', display: 'block', wordBreak: 'keep-all', overflow: 'scroll'}}
            ><Highlight text={currentTitle} keywords={keywords} plainText={true}/></span>
            <button
              type="button"
              className={`btn badge rounded-pill text-donut ${isShare ? 'd-none' : ''}`}
              onClick={activateEdit}
            >
              <i className="fa-fw fa-solid fa-pencil" />
            </button>
          </>
        )
      }
    </form>
  );
});

const downloadText = (text: string, fileName = 'meeting minutes.txt') => {
  saveAs(new Blob([text]), fileName);
};

/**
 * Collect messages in specific language for summary,
 * or in all languages if unspecified.
 */
function collectMessageForSummary(meeting: Meeting, lang?: string) {
  const msgs = Meeting.getAllMessages(meeting, lang);

  if (lang) {
    return msgs.map((msg) => `${msg.user}: ${msg.messages[lang]}`).join('<br>');
  } else {
    return msgs.map((msg) => {
      return `${msg.user}:\n${Object.entries(msg.messages).map(([lang, text]) => `${lang}: ${text}`).join('<br>')}<br>`;
    }).join('\n');
  }
}

// This is here to avoid copy-pasta.
export function openSummaryProcessView({meeting, allTags = [], keywords = [], isShare = false}: {meeting: Meeting, allTags?: string[], keywords?: string[], isShare?: boolean}) {
  return show({
    title: <MeetingTitleInput meeting={meeting} isShare={isShare} keywords={keywords} />,
    titleRight: !isShare && (<MeetingShareButtonBody meeting={meeting} />),
    content: <SummaryProcessView meeting={meeting} allTags={allTags} keywords={keywords} isShare={isShare} />,
    size: SIZE_MODE.XLARGE,
    btnMode: BUTTON_MODES.NONE,
  });
}

const MeetingMessage = ({meeting, message, onUpdate, onDelete, onAllUpdate, isShare }: {
  meeting: Meeting,
  message: DisplayChatMessage,
  onUpdate: (message_id: string, messages: { [lang: string]: string }) => void,
  onDelete: (message_id: string) => void,
  onAllUpdate: () => void,
  isShare: boolean,
}) => {
  const { t } = useTranslation();

  const [playing, setPlaying] = useState<boolean>(false);
  const playingRef = useRef<boolean>(playing);
  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);

  const [load, setLoad] = useState<boolean>(false);
  const loadRef = useRef<boolean>(load);
  useEffect(() => {
    loadRef.current = load;
  }, [load]);
  const cleanUp = CreateEvent(STOP_AUDIO_EVENT, () => {
    setPlaying(false);
    stop();
  });
  const [play, { stop }] = useSound(message.audioUrl ? message.audioUrl : '', {
    onend: () => {
      setPlaying(false);
      cleanUp();
    },
    onload: () => {
      setLoad(true);
    }
  });

  const onEditMessage = (message: {
    id: string,
    user: string,
    messages: { [lang: string]: string }
  }, lang: ILanguages, text: string) => {
    if (!message.messages[lang]) {
      return;
    }
    message.messages[lang] = text;
    onUpdate(message.id, message.messages);
  };

  const onClickDeleteMessage = () => {
    const is_delete = window.confirm(t('メッセージを削除します。よろしいですか？'));
    if (!is_delete) {
      return;
    }
    onDelete(message.id);
  };

  const onClickAudioPlay = async () => {
    if (playingRef.current) {
      await audioStop();
    } else {
      await audioPlay();
    }
  }

  const audioPlay = async () => {
    let success = false;
    try {
      if(loadRef.current && message.audioUrl && message.audioUrl !== ''){
        await fetch(message.audioUrl);
        DispatchEvent(STOP_AUDIO_EVENT)
        setPlaying(true);
        play();
        success = true;
      }
    } catch (e) {
      console.error(e)
      setPlaying(false);
      setLoad(false);
      cleanUp();
    }
    if (!success) {
      alert(t('音声を再生できません。有効期限が切れているか、ファイルが存在しません。'))
    }
  }

  const audioStop = async () => {
    setPlaying(false);
    stop();
    cleanUp();
  }

  const dtFormat = new Intl.DateTimeFormat('ja', {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  const updateSpeaker = useCallback(async (id: string, userName: string) => {
    await meeting.updateSpeakerUserName(id, userName);
  }, [meeting]);
  const updateCustomUserName = useCallback(async (userName: string, enabled: boolean, speakerId: string) => {
    await meeting.updateMessageCustomUserName(message.id, userName, enabled, speakerId);
  }, [meeting, message.id]);

  const handleUserNameButtonClick = useCallback(() => {
    return show({
      title: t('話者名の更新'),
      content: <MeetingUserNameConfigureBody
        speakers={meeting.speakers ?? []}
        speakerId={message.speakerId}
        userId={message.userId}
        userName={message.userName}
        enableCustomUserName={message.enableCustomUserName}
        customUserName={message.customUserName}
        updateSpeaker={updateSpeaker}
        updateCustomUserName={updateCustomUserName}
        onAllUpdate={onAllUpdate}
      />,
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
    });
  }, [t, meeting.speakers, message.speakerId, message.userId, message.userName, message.enableCustomUserName, message.customUserName, updateSpeaker, updateCustomUserName, onAllUpdate]);

  return <div className="mt-2">
    {!isShare ? (
      <button
        type="button"
        className="text-primary btn btn-link p-0 m-0 border-0 align-baseline"
        onClick={handleUserNameButtonClick}
      >{message.user}</button>
    ) : (
      <span
        className="text-donut"
      >{message.user}</span>
    )}
    {message.createdAt &&
      <span className="px-2 text-donut"><small>{dtFormat.format(message.createdAt)}</small></span>
    }
    {message.audioUrl &&
      <button className="btn btn-primary btn-sm b-inline-block mt-0 mb-0 me-0 ms-1 pt-0 pb-0"
              style={{ verticalAlign: '0', fontSize: '0.75em' }}
              onClick={onClickAudioPlay}><i
        className={`bi ${playing ? 'bi-pause-fill' : 'bi-play-fill'}`} />{playing ? t('停止') : t('再生')}</button>
    }
    {!isShare && (<button className="btn btn-danger btn-sm b-inline-block mt-0 mb-0 me-0 ms-1 pt-0 pb-0"
                          style={{ verticalAlign: '0', fontSize: '0.75em' }}
                          onClick={onClickDeleteMessage}>{t('削除')}</button>)}
    {Object.entries(message.messages).map(([lang, text]) => (
      <EditMessage
        key={lang}
        lang={lang as ILanguages}
        text={text}
        onEdit={(text) => onEditMessage(message, lang as ILanguages, text)} isShare={isShare} />
    ))}
  </div>;
};

const SummaryProcessView = observer(({ meeting, allTags, keywords, isShare }: {
  meeting: Meeting,
  allTags: string[],
  keywords: string[],
  isShare: boolean,
}) => {
  const { t } = useTranslation();
  const { getMarkdown, isInitialMinutes } = useMinutes();

  const messageText = useMemo(() => collectMessageForSummary(meeting), [meeting]);
  const [agenda, setAgenda] = useState<string>(summaryAgenda);
  const [isLoading, setIsLoading] = useState(true);

  const [messages, setMessages] = useState<DisplayChatMessage[]>([]);
  const getAllMessages = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedMessages: ChatMessage[] = [];
      if (!meeting.messages) {
        const snapshot = await getMeetingMessages(meeting.owner, meeting.id);
        snapshot.docs.forEach((doc) => {
          const message = doc.data() as ChatMessage;
          fetchedMessages.push(message);
        });
      }
      setMessages([...Meeting.getAllMessages(meeting, undefined, fetchedMessages)]);
    } finally {
      setIsLoading(false);
    }
  }, [meeting]);
  useEffect(() => {
    (async() => {
      await getAllMessages();
    })()
  }, [getAllMessages]);

  const isOnPc = useMediaQuery(bootstrapQuery.lg);

  const [selectedTab, setSelectedTab] = useState<'minutes' | 'aiSummary'>(
    meeting.summary ? 'aiSummary' : 'minutes',
  );

  const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTab('aiSummary');
    setAgenda(e.target.value);
  };

  const handleMinuteChangeMobile = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedTab('aiSummary');
    setAgenda(e.currentTarget.value);
  };

  const handleDownloadClick = async () => {
    let text = '';
    if (meeting.realtimeMinutes) {
      for (const realtimeMinute of meeting.realtimeMinutes) {
        if (realtimeMinute.markdown !== initialMinutes && realtimeMinute.markdown !== initialSummary) {
          text += `【${t(realtimeMinute.agenda)}】\n`;
          text += `${RemoveMarkdown(getMarkdown(realtimeMinute))}\n\n`;
        }
      }
    }
    text += `\n【${t('会話ログ')}】\n${messageText.trim().replaceAll('<br>', '\n')}`;
    downloadText(text, `${meeting.title}.txt`);
  };

  const handleAgendaDownloadClick = () => {
    let text = '';
    if (meeting.additionalDocument && meeting.additionalDocument !== '') {
      text += meeting.additionalDocument;
    }
    downloadText(text, `${meeting.title}_agenda.txt`);
  };

  const handleFilesDownloadClick = async () => {
    await show({
      title: t('事前資料のダウンロード'),
      content: <MeetingFileList meetingId={meeting.id} />,
      btnMode: BUTTON_MODES.NONE,
      size: SIZE_MODE.LARGE,
    });
  };

  const handleAudioDownload = async () => {
    try {
      const response = await fetch(meeting.fullAudioUrl!);
      if (!response.ok) {
        alert(t('音声を再生できません。有効期限が切れているか、ファイルが存在しません。'))
      }

      const blob = await response.blob();
      saveAs(blob, 'audio.mp3');
    } catch (error) {
      alert(t('音声を再生できません。有効期限が切れているか、ファイルが存在しません。'))
    }
  }

  const handleSummary = useCallback(async (confirm: boolean = true) => {
    if (!TextDecoder) {
      return window.alert(t('このブラウザは対応しておりません。より新しいブラウザをご利用ください。'));
    }

    let ok = meeting.summaryStatus !== SummaryStatus.PROCESSING;
    if (confirm) {
      ok = await show({
        content: t('要約を再度実行してもよろしいですか？'),
      });
    }

    if (ok) {
      const { summaryStatus } = meeting;
      meeting.setData({ summaryStatus: SummaryStatus.PROCESSING });

      await meeting.fetchMessages(true);

      // need to also perform message combination here because global messageText may not available yet
      const text = collectMessageForSummary(meeting);

      if (text && text.trim() !== '') {
        updateSummaryMinutes(meeting.id).catch((err) => {
          // log to sentry
          sentryLog(err);
          // restore data
          meeting.setData({ summaryStatus });
        });
      } else {
        meeting.setData({ summaryStatus });
        alert(t('この会議のメッセージがありません'));
      }
    }
  }, [meeting, t]);

  useEffect(() => {
    if (!isShare && meeting.messages?.length && (!meeting.realtimeMinutes || meeting.realtimeMinutes.length === 0 || isInitialMinutes(meeting.realtimeMinutes))) {
      handleSummary(false);
    }
  }, [meeting, handleSummary, isInitialMinutes, isShare]);

  const confirmDeleteMeeting = async () => {
    const requestDeleteMeeting = () => {
      deleteRoom(meeting.id)
        .then(() => {
          hideAll();
        })
        .catch((e) => {
          console.error(e);
          alert(t('エラーが発生しました。再度お試しください。'))
        });
    };

    await show({
      title: t('会議の削除'),
      content: (<div>
          <p>{t('以下の会議を削除します。')}</p>
          <p>{t('削除された会議は元に戻すことができません。よろしいですか？')}</p>
          <div>
            <h5 className="h5">{meeting.title}</h5>
            <h6 className="text-donut">{meeting.createdAt}</h6>
          </div>
          <hr />
          <section className="mt-3 d-flex gap-2 justify-content-end">
            <button onClick={() => hide()} className="btn btn-secondary rounded-pill">
              {t('キャンセル')}
            </button>
            <button onClick={requestDeleteMeeting} className="btn btn-danger rounded-pill">
              {t('削除する')}
            </button>
          </section>
        </div>
      ),
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
    });
  };

  const onMessageUpdate = (message_id: string, messages: { [lang: string]: string }) => {
    if (!meeting.messages) {
      return;
    }
    const index = meeting.messages.findIndex(m => m.id === message_id);
    if (index === -1) {
      return;
    }

    const target = meeting.messages[index];
    target.messages = messages;
    meeting.updateMessageText(target);
  };

  const onMessageDelete = (message_id: string) => {
    if (!meeting.messages) {
      return;
    }

    const index = meeting.messages.findIndex(m => m.id === message_id);
    if (index === -1) {
      return;
    }
    meeting.messages.splice(index, 1);
    meeting.deleteMessage(message_id);
  };

  return (
    <div className="d-flex flex-column" style={{ maxHeight: '70vh', marginTop: '-8px' }}>
      <EditTag meeting={meeting} isShare={isShare} allTags={allTags}/>
      <hr className="mt-1 mb-2" />
      {!isOnPc && (
        <ul className="navtab-style-restoration nav nav-tabs mb-2 mt-0">
          <li className="nav-item dropdown">
            <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{agenda}</button>
            <ul className="dropdown-menu">
              {meeting.realtimeMinutes && meeting.realtimeMinutes.length !== 0 ? (
                <>
                  {meeting.realtimeMinutes.filter(r => r.markdown !== initialSummary && r.markdown !== initialMinutes).map((item, index) => (
                    <li key={item.agenda}>
                      <button value={item.agenda} className="dropdown-item"
                              onClick={handleMinuteChangeMobile}>{t(item.agenda)}</button>
                    </li>
                  ))}
                </>
              ) : (
                <span className="fs-6">{t('AIによる要約は行われていません')}</span>
              )}
            </ul>
          </li>
          <li className="nav-item">
            <button onClick={() => setSelectedTab('minutes')}
                    className={'nav-link' + (selectedTab === 'minutes' ? ' active' : '')}
            >{t('議事録')}</button>
          </li>
        </ul>
      )}

      <div className="flex-shrink-1 d-flex gap-2 overflow-hidden">
        {(isOnPc || selectedTab === 'aiSummary') ? (
          <aside className="ai-section d-flex flex-column">
            {/*
            Summary Title
            */}
            <div className="d-flex section-title gap-1">
              {isOnPc &&
                <h5 className="flex-fill ms-2 mb-0">
                  {meeting.realtimeMinutes && meeting.realtimeMinutes.length !== 0 ? (
                    <select className="form-select" onChange={handleMinuteChange}>
                      {meeting.realtimeMinutes.filter(r => r.markdown !== initialSummary && r.markdown !== initialMinutes).map((item, index) => (
                        <option key={item.agenda} value={item.agenda}>{t(item.agenda)}</option>
                      ))}
                    </select>
                  ) : (
                    <span className="fs-6">{t('AIによる要約は行われていません')}</span>
                  )}
                </h5>
              }

              <MyTippy
                content={(
                  meeting.summaryStatus === SummaryStatus.PROCESSING
                    ? t('生成中です…')
                    : (meeting.summaryStatus === SummaryStatus.READY)
                      ? t('議事録を再生成する')
                      : t('議事録を生成する')
                )}
              >
                {!isShare && (<button
                  className="btn btn-primary badge rounded-pill"
                  onClick={() => handleSummary()}
                  disabled={meeting.summaryStatus === SummaryStatus.PROCESSING}
                >
                  <i className="fa-fw fa-solid fa-refresh" />
                </button>)}
              </MyTippy>

              <CopyToClipboard
                value={meeting.realtimeMinutes && meeting.realtimeMinutes.findIndex(m => m.agenda === agenda) !== -1 ? getMarkdown(meeting.realtimeMinutes.find(m => m.agenda === agenda)!) : ''}
                disabled={!meeting.realtimeMinutes || meeting.realtimeMinutes.length === 0}
              />
            </div>
            {isOnPc && <hr />}
            {/*
            Summary Content
            */}
            <div className="mt-1 flex-fill flex-grow-1 overflow-hidden d-flex">
              <div className="overflow-y-auto flex-fill p-1">
                <div className="summary-bubble p-2">
                  {(!meeting.realtimeMinutes || meeting.realtimeMinutes.length === 0) &&
                    <div>
                      <p className="text-donut">
                        {t('まだAIは要約を行っていないようです。')}
                      </p>
                      {(meeting.messages && meeting.messages.length > 0)
                        ? (
                          <p className="text-donut">
                            {t('右上の更新ボタンを押すと議事録の生成・更新が可能です。')}
                          </p>
                        ) : null}
                    </div>
                  }
                  {meeting.realtimeMinutes && meeting.realtimeMinutes.filter(r => r.markdown !== initialSummary && r.markdown !== initialMinutes).map((item, index) => (
                    <React.Fragment key={item.agenda}>
                      {agenda === item.agenda && (
                          item.markdown === initialMinutes ? <>t('まだAIは要約を行っていないようです。')</> : (
                            <Highlight text={getMarkdown(item)} keywords={keywords}/>
                          )
                        )
                      }
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </aside>
        ) : null}
        {(isOnPc || selectedTab === 'minutes') ? (
          <section className="minutes-section d-flex flex-column">
            {/*
            Minutes Title
            */}
            <div className="d-flex section-title">
              {isOnPc && (
                <h5 className="flex-fill mb-0">
                  {t('議事録')}
                </h5>
              )}
              <CopyToClipboard
                value={`[${t('議事録')}]:\n${messageText}`}
                disabled={!messageText}
              />
            </div>

            {isOnPc && <hr />}

            {/*
            Minutes Section
            */}
            <div className="mt-1 flex-fill flex-grow-1 overflow-hidden d-flex">
              <div className="overflow-y-auto flex-fill">
                {isLoading ? (
                  <div className="p-2 text-donut">
                    {t('取得中...')}
                  </div>
                ) : messages.length > 0 ? (
                  messages.map((message) => (
                    <MeetingMessage
                      key={message.id}
                      meeting={meeting}
                      message={message}
                      onUpdate={onMessageUpdate}
                      onDelete={onMessageDelete}
                      onAllUpdate={getAllMessages}
                      isShare={isShare}
                    />
                  ))
                ) : (
                  <div className="p-2 text-donut">
                    {t('議事録の中身がありません…')}
                  </div>
                )}
              </div>
            </div>
          </section>
        ) : null}

      </div>
      <hr />
      {/*
        Footer Section
      */}
      <section className="flex-shrink-1 d-xl-flex d-block gap-2 justify-content-between">
        {!isShare && (<button
          onClick={confirmDeleteMeeting}
          disabled={meeting.summaryStatus === SummaryStatus.PROCESSING}
          className="btn btn-danger rounded-pill w-xl-auto w-100 mb-1"
        >
          {t('この会議を削除')}
        </button>)}

        <div className={`${isShare ? 'text-end w-100' : 'w-xl-auto w-100'}`}>
          {(meeting.fullAudioUrl && meeting.fullAudioUrl !== '') && (
            <button
               className="btn btn-success rounded-pill me-3 w-md-auto w-100 mb-1"
               onClick={handleAudioDownload}>
              {t('音声のダウンロード')}
            </button>
          )}
          {(meeting.additionalDocument && meeting.additionalDocument !== '') && (
            <button
              onClick={handleAgendaDownloadClick}
              className="btn btn-success rounded-pill me-3 w-xl-auto w-100 mb-1"
            >
              {t('アジェンダのダウンロード')}
            </button>
          )}
          {meeting?.hasUploadedFiles && (
            <button
              onClick={handleFilesDownloadClick}
              className="btn btn-success rounded-pill me-3 w-xl-auto w-100 mb-1"
            >
              {t('事前資料のダウンロード')}
            </button>
          )}
          <LoadingButton
            className="rounded-pill w-xl-auto w-100 mb-1"
            onClick={handleDownloadClick}
            disabled={meeting.summaryStatus !== SummaryStatus.READY}
            isLoading={meeting.summaryStatus === SummaryStatus.PROCESSING}
          >
            {(meeting.summaryStatus === SummaryStatus.PROCESSING)
              ? t('処理中…')
              : t('議事録のダウンロード')
            }
          </LoadingButton>
        </div>
      </section>
    </div>
  );
});


export const MeetingText = observer(({ meeting, keywords, plainText = false, ellipsis }: {
  meeting: Meeting,
  keywords: string[],
  plainText?: boolean,
  ellipsis?: number
}) => {
  const { t } = useTranslation();
  const { getMarkdown } = useMinutes();

  const [loadingMessages, setLoadingMessages] = useState(false);
  const [hasMessages, setHasMessages] = useState(false);
  const lastCheckedMeetingId = useRef<string | null>(null);
  const lastRealtimeMinutes = useRef<string | null>(null);

  const checkMessagesExistence = useCallback(async () => {
    if (lastCheckedMeetingId.current === meeting.id) return;
    lastCheckedMeetingId.current = meeting.id;

    setLoadingMessages(true);
    const hasMessages = await isExistMeetingMessage(UserInfo.id, meeting.id); // 戻り値がtrueの場合、メッセージが存在
    setHasMessages(hasMessages); // 直接代入（!を使わない）
    setLoadingMessages(false);
  }, [meeting.id]);

  useEffect(() => {
    const summaryMinute = meeting.realtimeMinutes?.find(r => r.type === MINUTE.summary);
    const currentRealtimeMinutes = JSON.stringify(meeting.realtimeMinutes);
    const isErrorState = summaryMinute?.markdown === '要約・議事録を作成できませんでした。再要約をお試しください。';

    if (!isErrorState) {
      // エラー状態でない場合のリセット処理
      lastCheckedMeetingId.current = null;
      lastRealtimeMinutes.current = null;
      setHasMessages(false);
      return;
    }

    // lastRealtimeMinutesの更新は維持
    if (lastRealtimeMinutes.current !== currentRealtimeMinutes) {
      lastRealtimeMinutes.current = currentRealtimeMinutes;
    }
  }, [meeting.realtimeMinutes]);

  const getSummaryText = useMemo(() => {
    if (!meeting.realtimeMinutes?.length) {
      return t('議事録の中身がありません…');
    }

    const summaryMinute = meeting.realtimeMinutes.find(r => r.type === MINUTE.summary);
    if (!summaryMinute) {
      return t('議事録の中身がありません…');
    }

    // エラー状態に入った時点でローディング状態に
    const isErrorState = summaryMinute.markdown === '要約・議事録を作成できませんでした。再要約をお試しください。';
    if (isErrorState && !loadingMessages && !hasMessages) {
      // ローディング状態をトリガー
      checkMessagesExistence();
      return null;  // nullを返すことでローディング表示になる
    }

    // エラー状態の場合、メッセージ確認結果に基づいて表示
    if (isErrorState) {
      return hasMessages
        ? t('要約・議事録を作成できませんでした。再要約をお試しください。')
        : t('議事録の中身がありません…');
    }

    // 通常の表示処理
    if (!plainText) {
      return getMarkdown(summaryMinute);
    }

    return summaryMinute.summary?.length
      ? summaryMinute.summary.join(' ')
      : RemoveMarkdown(summaryMinute.markdown);
  }, [
    meeting.realtimeMinutes,
    plainText,
    getMarkdown,
    t,
    hasMessages,
    loadingMessages,
    checkMessagesExistence
  ]);

  const getDisplayContent = useMemo(() => {
    if (!getSummaryText) return <>-</>;

    const isEllipsisRequired = ellipsis !== undefined && getSummaryText.length > ellipsis;

    const text = isEllipsisRequired
      ? getSummaryText.substring(0, ellipsis)
      : getSummaryText;

    return (
      <>
        <Highlight text={text} keywords={keywords} plainText={plainText} />
        {isEllipsisRequired && '...'}
      </>
    );
  }, [getSummaryText, ellipsis, keywords, plainText]);

  const getContentByState = () => {
    if (loadingMessages) {
      return plainText ? (
        <>{t('取得中...')}</>
      ) : (
        <div className="meeting-minutes thumb mb-3"><span className="text-black-50">{t('取得中...')}</span></div>
      );
    }

    switch (meeting.state) {
      case 'building':
      case 'finish':
        return <p className="rounded-pill">{t('議事録作成を開始しました。完了までしばらくお待ち下さい。')}</p>;
      case 'waiting':
        return <>-</>;
      case 'incall':
        return <></>;
      case 'completed':
        return (
          <div className="meeting-minutes thumb mb-3">
            {getDisplayContent || <p className="text-donut p-3 text-center">{t('議事録の中身がありません…')}</p>}
          </div>
        );
      default:
        return <>-</>;
    }
  };

  return getContentByState();
});

export const MeetingDuration = observer(({ meeting, icon = true }: { meeting: Meeting, icon?: boolean }) => {
  /*
   * State Management Part:
   * Only create an interval when the meeting state is in-call.
   */
  const [dynTime, setDynTime] = useState<number>(0); // Only used when state === incall.
  const { t } = useTranslation();
  const state = meeting.state;

  useEffect(() => {
    let handle: NodeJS.Timer | null = null;

    if (state === 'incall') {
      handle = setInterval(() => {
        if (meeting.beginAt != null) {
          const nowInSec = Date.now();
          setDynTime((nowInSec - meeting.beginAt.getTime()) / 1000);
        }
      }, 1000);
    }

    return () => {
      if (handle) {
        clearInterval(handle);
      }
    };
  }, [state, meeting]);

  /*
   * Rendering Part:
   */
  const MINUTES = 60;
  const HOURS = 60 * MINUTES;

  let timeElapsed: number | null = null;

  if (meeting.state === 'waiting') {
    timeElapsed = meeting.allocatedTime || null;
  } else if (meeting.state === 'incall') {
    timeElapsed = dynTime;
  } else if (meeting.state === 'completed') {
    timeElapsed = meeting.timeElapsed;
  }

  if (timeElapsed != null) {
    const hoursElapsed = Math.floor(timeElapsed / HOURS);
    const minutesElapsed = (timeElapsed % HOURS) > MINUTES ? Math.floor((timeElapsed % HOURS) / MINUTES) : 0;

    const klass = className('bi', {
      'bi-clock': meeting.state === 'incall' || meeting.state === 'completed',
      'bi-clock-fill': meeting.state === 'waiting',
    });

    return <span>
      {icon && <i className={klass} />}
      {hoursElapsed > 0 ? t('time.hour', { count: hoursElapsed }) : ''} {t('time.minute', { count: minutesElapsed })}
    </span>;
  }

  return <>-</>;
});


export const MeetingItem = observer(({ meeting, notifications, allTags, keywords, layout }: {
  meeting: Meeting,
  notifications: NotificationFirebaseData[],
  allTags: string[],
  keywords: string[],
  layout: DisplayLayout,
}) => {
  const [loading, setLoading] = useState(meeting.state === 'building' || meeting.state === 'finish');
  const { state, messages } = meeting;
  const { t } = useTranslation();

  const meetingTextComponent = useMemo(() => (
    <MeetingText
      meeting={meeting}
      keywords={keywords}
      plainText={layout === DisplayLayout.list}
      ellipsis={layout === DisplayLayout.list ? 70 : undefined}
    />
  ), [meeting, keywords, layout]);

  const meetingDurationComponent = useMemo(() => (
    <MeetingDuration meeting={meeting} icon={layout === DisplayLayout.grid} />
  ), [layout, meeting]);

  useEffect(() => {
    if (state === 'completed') {
      if (!messages) {
        meeting.fetchMessages(false);
      }
    }
    if (state !== 'building' && meeting.state !== 'finish') {
      setLoading(false);
    }
  }, [meeting, state, messages]);

  const meetingURL = `${process.env.REACT_APP_MEETING_URL || ''}?t=${meeting.token}`;
  const [isCopyingURL, copyMeetingURL] = useCopyToClipboard(meetingURL);

  const handleFinish = useCallback(async () => {
    const ok = await show({
      title: t('会議の終了'),
      content: (<div>
        <p>{t('以下の会議を終了します。よろしいですか？')}</p>
        <div>
          <h5 className="h5">{meeting.title}</h5>
          <h6 className="text-donut">{meeting.createdAt}</h6>
        </div>
      </div>),
      okText: t('終了する'),
    });

    if (ok) {
      try {
        setLoading(true);
        // await updateMeeting(UserInfo.id, meeting.id, { state: MEETING_STATES.COMPLETED })
        meeting.setData({ state: MEETING_STATES.FINISH });
        const response = await finishMeeting(meeting.id);
        if (response.data.success) {
          if (meeting.beginAt && meeting.allocatedTime) {
            await UserInfo.refreshTime();
          }
          meeting.setData({ state: MEETING_STATES.COMPLETED });
        } else {
          meeting.setData({ state: MEETING_STATES.INCALL });
          alert(t('不明なエラー'));
        }
      } finally {
        setLoading(false);
      }
    }
  }, [meeting, t]);

  const handleStart = useCallback(async () => {
    const ok = await show({
      title: t('会議の開始'),
      content: (<div>
        <p>{t('以下の会議が開始されます。よろしいですか？')}</p>
        <div>
          <h5 className="h5">{meeting.title}</h5>
          <h6
            className="text-donut">{meeting.scheduledBeginAt ? `${t('開始予定時刻')}: ${convertDateTimeToStringForView(meeting.scheduledBeginAt)}` : meeting.createdAt}</h6>
        </div>
      </div>),
      okText: t('開始する'),
    });

    if (ok) {
      try {
        setLoading(true);
        // await updateMeeting(UserInfo.id, meeting.id, { state: MEETING_STATES.COMPLETED })
        const response = await beginMeeting(meeting.id);
        if (response.data.success) {
          meeting.setData({ beginAt: new Date(), state: MEETING_STATES.INCALL });
          window.open(meetingURL, '_blank')?.focus();
        } else {
          alert(t('不明なエラー'));
        }
      } finally {
        setLoading(false);
      }
    }
  }, [meeting, meetingURL, t]);

  const openSummary = async (handleWaiting: boolean = false) => {
    if (meeting.state === 'completed') {
      await meeting.fetchMessages(true);
      if (notifications.length !== 0) {
        for (const notification of notifications) {
          if (!notification.read) {
            try {
              readNotifications(UserInfo.id, notification._id);
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
      openSummaryProcessView({meeting, allTags, keywords});
    } else if (handleWaiting && meeting.state === 'waiting') {
      await handleStart()
    }
  };

  useEffect(() => {
    const showMeetingEventListener = (event: Event) => {
      const customEvent = event as CustomEvent<{ meeting: string }>;
      if (customEvent.detail.meeting === meeting.id) {
        openSummaryProcessView({meeting, allTags, keywords});
      }
    }
    document.addEventListener('showMeeting', showMeetingEventListener);

    return () => {
      document.removeEventListener('showMeeting', showMeetingEventListener);
    }
  }, [allTags, keywords, meeting]);

  const handleShareClick = useCallback(async () => {
    await show({
      title: t('共有設定'),
      content: <MeetingShareConfigureBody meeting={meeting} />,
      btnMode: BUTTON_MODES.NONE,
    });
  }, [meeting, t]);

  const { getMarkdown } = useMinutes();
  const messageText = useMemo(() => collectMessageForSummary(meeting), [meeting]);
  const handleDownloadClick = async () => {
    let text = '';
    if (meeting.realtimeMinutes) {
      for (const realtimeMinute of meeting.realtimeMinutes) {
        if (realtimeMinute.markdown !== initialMinutes && realtimeMinute.markdown !== initialSummary) {
          text += `【${t(realtimeMinute.agenda)}】\n`;
          text += `${RemoveMarkdown(getMarkdown(realtimeMinute))}\n\n`;
        }
      }
    }
    text += `\n【${t('会話ログ')}】\n${messageText.trim().replaceAll('<br>', '\n')}`;
    downloadText(text, `${meeting.title}.txt`);
  };

  const confirmDeleteMeeting = async () => {
    const requestDeleteMeeting = () => {
      deleteRoom(meeting.id)
        .then(() => {
          hideAll();
        })
        .catch((e) => {
          console.error(e);
          alert(t('エラーが発生しました。再度お試しください。'))
        });
    };

    await show({
      title: t('会議の削除'),
      content: (<div>
          <p>{t('以下の会議を削除します。')}</p>
          <p>{t('削除された会議は元に戻すことができません。よろしいですか？')}</p>
          <div>
            <h5 className="h5">{meeting.title}</h5>
            <h6 className="text-donut">{meeting.createdAt}</h6>
          </div>
          <hr />
          <section className="mt-3 d-flex gap-2 justify-content-end">
            <button onClick={() => hide()} className="btn btn-secondary rounded-pill">
              {t('キャンセル')}
            </button>
            <button onClick={requestDeleteMeeting} className="btn btn-danger rounded-pill">
              {t('削除する')}
            </button>
          </section>
        </div>
      ),
      size: SIZE_MODE.MEDIUM,
      btnMode: BUTTON_MODES.NONE,
    });
  };

  return layout === DisplayLayout.grid ? (
    <div key={meeting.id} className={`col-auto mb-4 ${loading ? 'disabled' : ''}`}>
      <div
        className={`meeting-item d-flex flex-column justify-content-between ${meeting.state === 'completed' ? 'finished' : ''}`}
        onClick={() => openSummary()}
      >
        <div className="d-flex flex-column mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <small>{(meeting.beginAt && convertDateTimeToStringForView(meeting.beginAt)) || (meeting.scheduledBeginAt && convertDateTimeToStringForView(meeting.scheduledBeginAt)) || meeting.createdAt}</small>
          </div>
          <h4 className="meeting-item-title pb-1 mb-2"><Highlight text={meeting.title} keywords={keywords} plainText={true}/></h4>
          <div className="d-flex gap-2">
            {meeting.state === 'completed' && !!meeting.numberOfSpeakers && (
              <span>
                  <i className="bi bi-people-fill" /> {meeting.numberOfSpeakers}
                </span>
            )}
            {meetingDurationComponent}
          </div>
        </div>
        {meetingTextComponent}
        <div className="d-flex flex-column align-items-center gap-3">
          {meeting.state === 'waiting' && (
            <>
              <button onClick={copyMeetingURL}
                      className={`btn rounded-pill ${isCopyingURL ? 'btn-success' : 'btn-outline-primary'}`}>
                {isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}
              </button>
              <button onClick={handleStart}
                      className="btn btn-success rounded-pill">
                {t('会議を開始する')}
              </button>
              <button onClick={confirmDeleteMeeting}
                      className="btn btn-danger rounded-pill">
                {t('キャンセル')}
              </button>
            </>
          )}
          {meeting.state === 'incall' && (
            <>
              <button onClick={copyMeetingURL}
                      className={`btn rounded-pill ${isCopyingURL ? 'btn-success' : 'btn-outline-primary'}`}>
                {isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}
              </button>
              <button onClick={handleFinish}
                      className="btn btn-primary rounded-pill">
                {t('会議を終了する')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <tr
      key={meeting.id}
      className={`col-auto mb-4 ${meeting.state === 'completed' || meeting.state === 'waiting' ? 'clickable' : ''}`}
    >
      <td onClick={() => openSummary(true)} className={`text-center ${loading ? 'disabled' : ''}`}><span className={`badge rounded-pill text-bg-${stateViewStr[meeting.state as MEETING_STATES_TYPE]?.color}`}>{t(stateViewStr[meeting.state as MEETING_STATES_TYPE]?.i18n)}</span></td>
      <td onClick={() => openSummary(true)} className={`${loading ? 'disabled' : ''}`}><Highlight text={meeting.title} keywords={keywords} plainText={true}/></td>
      <td onClick={() => openSummary(true)} className={`${loading ? 'disabled' : ''}`}>{(meeting.beginAt && convertDateTimeToStringForView(meeting.beginAt, null, true)) || (meeting.scheduledBeginAt && convertDateTimeToStringForView(meeting.scheduledBeginAt, null, true)) || meeting.createdAt}</td>
      <td onClick={() => openSummary(true)} className={`${loading ? 'disabled' : ''}`}>{meeting.state === 'completed' && meeting.numberOfSpeakers ? `${meeting.numberOfSpeakers}${t('人')}` : '-'}</td>
      <td onClick={() => openSummary(true)} className={`${loading ? 'disabled' : ''}`}>{meetingDurationComponent}</td>
      <td onClick={() => openSummary(true)}>{meetingTextComponent}</td>
      <td className="action">
        {(meeting.state === 'waiting' || meeting.state === 'incall' || meeting.state === 'completed' || meeting.state === 'building' || meeting.state === 'finish') && (
          <div className="dropdown dropstart">
            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i className="bi bi-three-dots-vertical"></i>
            </button>
            <ul className="dropdown-menu">
              {(meeting.state === 'building' || meeting.state === 'finish') && (
                <>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      disabled={meeting.summaryStatus === SummaryStatus.PROCESSING}
                      onClick={confirmDeleteMeeting}
                    >{t('この会議を削除')}</button>
                  </li>
                </>
              )}
              {meeting.state === 'waiting' && (
                <>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={copyMeetingURL}
                    >{isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}</button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={handleStart}
                    >{t('会議を開始する')}</button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={confirmDeleteMeeting}
                    >{t('キャンセル')}</button>
                  </li>
                </>
              )}
              {meeting.state === 'incall' && (
                <>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={copyMeetingURL}
                    >{isCopyingURL ? t('コピーしました！') : t('招待リンクのコピー')}</button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={handleFinish}
                    >{t('会議を終了する')}</button>
                  </li>
                </>
              )}
              {meeting.state === 'completed' && (
                <>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={handleShareClick}
                    >{t('共有')}</button>
                  </li>
                  <li>
                    <LoadingButton
                      type="button"
                      className="dropdown-item"
                      onClick={handleDownloadClick}
                      disabled={meeting.summaryStatus !== SummaryStatus.READY}
                      isLoading={meeting.summaryStatus === SummaryStatus.PROCESSING}
                    >
                      {(meeting.summaryStatus === SummaryStatus.PROCESSING)
                        ? t('処理中…')
                        : t('議事録のダウンロード')
                      }
                    </LoadingButton>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
      </td>
    </tr>
  );
});
