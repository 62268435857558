import './HomePage.css'

import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'

import { Menu } from './Menu'
import Routes from '../../Routes';
import { NotificationFirebaseData } from '../../api/MeetingFirebaseApi';
import { Meeting } from '../../store/model/Meeting';

const HomePage = ({notifications, meetings}: {notifications: NotificationFirebaseData[], meetings: Meeting[]}) => {
  return (
    <>
      <div className='menu-big d-none d-sm-block'>
        <Menu style={{ paddingRight: '20px', visibility: 'hidden' }} />
        <div className='real-menu hide-scroll'>
          <Menu />
        </div>
      </div>
      <div className='page-content'>
        <div>
          <div className='logo-bg'>
            <img src='/logo.png' alt='LOGO' />
          </div>
          <Switch>
            {Routes.map(({ path, exact, component, requiresNotificationsAndMeetings }) => {
              const Component = component
              if (!Component) {
                return <></>;
              }

              if (requiresNotificationsAndMeetings) {
                return (
                  <Route key={path} path={path} exact={exact}
                         render={() => <Component notifications={notifications} meetings={meetings} />}
                  />
                );
              }

              return (
                <Route key={path} path={path} exact={exact}>
                  <Component />
                </Route>
              )
            })}
          </Switch>
        </div>
      </div>
    </>
  )
}

export default observer(HomePage)
