import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  updateDoc,
  where,
} from 'firebase/firestore';

import { firestore } from '../firebase';
import { Speaker } from '../store/model/Meeting';

export const MEETING_STATES = {
  BUILDING: 'building',
  WAITING: 'waiting',
  INCALL: 'incall',
  FINISH: 'finish',
  COMPLETED: 'completed',
} as const
export type MEETING_STATES_TYPE = typeof MEETING_STATES[keyof typeof MEETING_STATES];

export type SttDictionariesFirebaseData = {
  id?: string;
  targetWord: string;
  sourceWords?: string[];
};

export enum NOTIFICATION_TYPE {
  'info' = 'info',
  'success' = 'success',
  'error' = 'error',
}
export type NotificationFirebaseData = {
  _id: string;
  read: boolean;
  type: NOTIFICATION_TYPE;
  title: string;
  body: string;
  link?: string;
  meeting?: string
};

export enum SHARE_SCOPE_TYPE {
  'public' = 'public', // 全公開
  'members' = 'members', // メンバーのみ
  'email' = 'email', // メールアドレス指定
  'private' = 'private', // 非公開
}

export const getMeetingByTitle = (user: string | number | undefined, meetingTitle: string) => {
  const meetings = collection(firestore, `users/${user}/meetings`)
  const q = query(meetings, where('title', '==', meetingTitle))
  return getDocs(q)
}

export const getMeeting = (user: string | number | undefined, meeting: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return getDoc(m)
}

export const getMeetings = (user: string | number | undefined) => {
  const meetings = collection(firestore, `users/${user}/meetings`)
  const q = query(meetings, orderBy('createdAt', 'desc'))
  return getDocs(q)
}

export const deleteMeeting = (user: string | number | undefined, meeting: string) => {
  // TODO: delete messages, because Firestore does not automatically delete sub collections

  // delete meeting
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return deleteDoc(m)
}

export const updateMeeting = (user: string | number | undefined, meeting: string, data: any) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}`)
  return updateDoc(m, data)
}

export const getMeetingMessages = (user: string | number | undefined, meeting: string, itemLimit: number = -1) => {
  const messages = collection(firestore, `users/${user}/meetings/${meeting}/messages`)
  const queries = []

  // TODO TODO TODO TODO: This is a temporary fix for
  // really huge document fetching problem.
  // THIS DOES NOT SOLVE THE BASE PROBLEM AND THUS NEEDS TO BE REVISITED ASAP.
  queries.push(orderBy('createdAt', 'asc'))
  if (itemLimit > 0) {
    queries.push(limit(itemLimit))
  }

  const q = query(messages, ...queries)
  return getDocs(q)
}

export const getMeetingMessage = (user: string | number | undefined, meeting: string, message: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return getDoc(m)
}

export const isExistMeetingMessage = async (user: string | number | undefined, meeting: string) => {
  const m = collection(firestore, `users/${user}/meetings/${meeting}/messages`);
  const q = query(m, limit(1));
  const snapshot = await getDocs(q);
  return !snapshot.empty;
}

export const updateMeetingMessage = (user: string | number | undefined, meeting: string, message: string, data: any) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return updateDoc(m, data)
}

export const deleteMeetingMessage = async (user: string | number | undefined, meeting: string, message: string) => {
  const m = doc(firestore, `users/${user}/meetings/${meeting}/messages/${message}`)
  return deleteDoc(m)
}

export const updateMeetingMessageText = async (user: string | number | undefined, meeting: string, message: string, messages: { [lang: string]: string }) => {
  const doc = await getMeetingMessage(user, meeting, message);
  const data = doc.data();
  if(!data || !data.messages){
    return;
  }
  for (const [lang, text] of Object.entries(messages)) {
    if(data && data.messages[lang]){
      data.messages[lang] = text;
    }
  }
  await updateMeetingMessage(user, meeting, message, data);
}

export const updateMeetingMessageCustomUserName = async (user: string | number | undefined, meeting: string, message: string, userName: string, enabled: boolean, speakerId: string) => {
  const doc = await getMeetingMessage(user, meeting, message);
  const data = doc.data();
  if(!data){
    return;
  }
  data.customUserName = userName;
  data.enableCustomUserName = enabled;
  data.speakerId = speakerId;
  await updateMeetingMessage(user, meeting, message, data);
}

export const updateMeetingSpeakerUserName = async (user: string | number | undefined, meeting: string, speaker: string, userName: string) => {
  const doc = await getMeeting(user, meeting);
  const data = doc.data();
  if(!data){
    return;
  }
  if (!data.speakers) {
    data.speakers = [];
  }

  const index = data.speakers.findIndex((s: { id: string; }) => s.id === speaker);
  if (index === -1) {
    data.speakers.push({id: speaker, username: userName})
  } else {
    data.speakers[index].username = userName;
  }
  await updateMeeting(user, meeting, data);
}

export const updateMeetingSpeakers = async (user: string | number | undefined, meeting: string, speakers: Speaker[]) => {
  const doc = await getMeeting(user, meeting);
  const data = doc.data();
  if(!data){
    return;
  }
  data.speakers = speakers;
  await updateMeeting(user, meeting, data);
}

export const updateMeetingNumberOfSpeakers = async (user: string | number | undefined, meeting: string, numberOfSpeakers: number) => {
  const doc = await getMeeting(user, meeting);
  const data = doc.data();
  if(!data){
    return;
  }
  data.numberOfSpeakers = numberOfSpeakers;
  await updateMeeting(user, meeting, data);
}

export const updateMeetingHasUploadedFiles = async (user: string | number | undefined, meeting: string, hasUploadedFiles: boolean) => {
  const doc = await getMeeting(user, meeting);
  const data = doc.data();
  if(!data){
    return;
  }
  data.hasUploadedFiles = hasUploadedFiles;
  await updateMeeting(user, meeting, data);
}

export const getSystemSttDictionaries = async (): Promise<SttDictionariesFirebaseData[]> => {
  const sttDictionaries = collection(firestore, `config/general/sttDictionaries`)

  const queries = []
  queries.push(orderBy('targetWord', 'asc'))

  const q = query(sttDictionaries, ...queries)
  const snap = await getDocs(q);

  const arr: SttDictionariesFirebaseData[] = [];
  snap.docs.forEach((doc) => {
    const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
    arr.push(sttDictionariesData);
  });
  return arr;
}

export const getUserSttDictionaries = async (user: string | number | undefined): Promise<SttDictionariesFirebaseData[]> => {
  const sttDictionaries = collection(firestore, `users/${user}/sttDictionaries`)

  const queries = []
  queries.push(orderBy('targetWord', 'asc'))

  const q = query(sttDictionaries, ...queries)
  const snap = await getDocs(q);

  const arr: SttDictionariesFirebaseData[] = [];
  snap.docs.forEach((doc) => {
    const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
    arr.push({...sttDictionariesData, id: doc.id});
  });
  return arr;
}

export const createUserSttDictionaries = (user: string | number | undefined, data: SttDictionariesFirebaseData) => {
  const sttDictionaries = collection(firestore, `users/${user}/sttDictionaries`)
  return addDoc(sttDictionaries, data);
}

export const updateUserSttDictionaries = (user: string | number | undefined, data: SttDictionariesFirebaseData) => {
  const m = doc(firestore, `users/${user}/sttDictionaries/${data.id}`)
  return updateDoc(m, data)
}

export const deleteUserSttDictionaries = (user: string | number | undefined, id: string) => {
  const m = doc(firestore, `users/${user}/sttDictionaries/${id}`)
  return deleteDoc(m)
}

// 通知情報の取得
export const getNotifications = async (user: string | number | undefined, onUpdate: (data: NotificationFirebaseData[]) => void): Promise<Unsubscribe> => {
  const notifications = collection(firestore, `users/${user}/notifications`);

  const queries = [];
  queries.push(orderBy('createdAt', 'desc'));

  const q = query(notifications, ...queries);

  // リアルタイムリスナーを設定
  return onSnapshot(q, (snapshot) => {
    //console.log('notification sync')
    const arr: NotificationFirebaseData[] = [];
    snapshot.docs.forEach((doc) => {
      const notificationFirebaseData = doc.data() as NotificationFirebaseData;
      arr.push(notificationFirebaseData);
    });

    // コールバック関数を呼び出して更新を通知
    onUpdate(arr);
  });
}

// 通知既読
export const readNotifications  = (user: string | number | undefined, notification_id: string) => {
  const m = doc(firestore, `users/${user}/notifications/${notification_id}`)
  return updateDoc(m, {read: true})
}
