import './SelectPreviousMeeting.css';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import UserInfo from '../../store/UserInfo';
import { convertDateTimeToStringForView } from '../../util/date';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BUTTON_MODES, hide, show, SIZE_MODE } from '../../view/PopupEvent';
import { Meeting } from '../../store/model/Meeting';
import { MeetingFileList } from './MeetingFileList';
import MeetingTaggedSearchInput from './MeetingTaggedSearchInput';
import Highlight from '../../view/Highlight';

export const SelectPreviousMeeting = observer(({ initSelectedMeetingIds, onSelect }: {
  initSelectedMeetingIds: Set<string>,
  onSelect: (meetingIds: Set<string>) => void
}) => {
  const { t } = useTranslation();
  const [searching, setSearching] = useState<boolean>(true);
  const [displayMeetings, setDisplayMeetings] = useState<Meeting[]>([]);
  const [completedMeetings, setCompletedMeetings] = useState<Meeting[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (!UserInfo.meetings) return;
    const completed = UserInfo.meetings.filterAndSort({
      filterFields: {state: "completed"},
    });
    setCompletedMeetings(completed);
    setDisplayMeetings(completed);
  }, []);

  const [selectedMeetingIds, setSelectedMeetingIds] = useState<Set<string>>(initSelectedMeetingIds);
  const selectedMeetingIdsRef = useRef<Set<string>>(selectedMeetingIds);
  useEffect(() => {
    selectedMeetingIdsRef.current = selectedMeetingIds;
  }, [selectedMeetingIds]);

  const onMeetingIdsChange = (meetingId: string) => {
    setSelectedMeetingIds(prev => {
      const next = new Set(prev);
      if (next.has(meetingId)) {
        next.delete(meetingId);
      } else {
        next.add(meetingId);
      }
      return next;
    });
  };

  const handleSelectAll = () => {
    if (selectedMeetingIds.size === completedMeetings.length) {
      setSelectedMeetingIds(new Set());
    } else {
      setSelectedMeetingIds(new Set(completedMeetings.map(m => m.id)));
    }
  };

  const onMeetingIdsSelect = () => {
    onSelect(selectedMeetingIdsRef.current);
    hide();
  };

  // 表示用に20件ずつに分割
  const [displayCount, setDisplayCount] = useState(20);
  const displayedMeetings = useMemo(() =>
      displayMeetings.slice(0, displayCount),
    [displayMeetings, displayCount]
  );

  // Intersection Observerで無限スクロール
  const observerTarget = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayCount < completedMeetings.length) {
          setDisplayCount(prev => Math.min(prev + 20, completedMeetings.length));
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.unobserve(observerTarget.current);
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [completedMeetings.length, displayCount]);

  const handleShowAdditionalDocumentClick = async (meeting: Meeting) => {
    await show({
      title: t('アジェンダの内容'),
      content: <pre style={{ whiteSpace: 'pre-line' }}>{meeting.additionalDocument}</pre>,
      btnMode: BUTTON_MODES.NONE,
      size: SIZE_MODE.LARGE,
    });
  };

  const handleFilesDownloadClick = async (meeting: Meeting) => {
    await show({
      title: t('事前資料のダウンロード'),
      content: <MeetingFileList meetingId={meeting.id} />,
      btnMode: BUTTON_MODES.NONE,
      size: SIZE_MODE.LARGE,
    });
  };

  return (
    <div className="d-flex flex-column h-100">
      {UserInfo.meetings?.loaded && (
        <MeetingTaggedSearchInput
          meetings={completedMeetings}
          onSearching={setSearching}
          onFilteredMeetingsChange={setDisplayMeetings}
          onKeywordsChange={setKeywords}
          sortField="beginAt"
          sortDirection="desc"
          placeholder={t('検索キーワードを入力...')}
          className="previous-meeting"
        />
      )}
      <div className="table-responsive flex-grow-1" style={{ height: '60vh' }}>
        {UserInfo.meetings?.loaded ? (
          UserInfo.meetings.dataArray.length ? (
            displayMeetings.length ? (
              <table className="table table-hover">
                <thead className="sticky-top bg-white">
                <tr>
                  <th scope="col" style={{ minWidth: '6em' }}>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={selectedMeetingIds.size === completedMeetings.length}
                      onChange={handleSelectAll}
                    />
                    {t('選択')}
                  </th>
                  <th scope="col" style={{ minWidth: '10em' }}>{t('タイトル')}</th>
                  <th scope="col" style={{ minWidth: '4em' }} className="text-center">{t('人数')}</th>
                  {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                    <th scope="col" style={{ minWidth: '7em' }} className="text-center">{t('アジェンダ')}</th>
                  )}
                  {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                    <th scope="col" style={{ minWidth: '7em' }} className="text-center">{t('事前資料')}</th>
                  )}
                  <th scope="col" style={{ minWidth: '13em' }} className="text-center">{t('開始日時')}</th>
                </tr>
                </thead>
                <tbody>
                  {displayedMeetings.map((meeting) => (
                    <tr onClick={(event) => {
                      event.stopPropagation();
                      onMeetingIdsChange(meeting.id);
                    }} key={meeting.id} style={{ cursor: 'pointer' }}>
                      <th scope="row" className={`align-middle ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}>
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={meeting.id}
                          checked={selectedMeetingIds.has(meeting.id)}
                          onChange={() => {
                          }} // Prevent warnings from occurring in your development environment
                          onClick={() => {
                          }}
                        />
                      </th>
                      <td
                        className={`align-middle ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}
                      ><Highlight text={meeting.title} keywords={keywords} plainText={true}/></td>
                      <td
                        className={`align-middle text-center ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}
                      >{meeting.numberOfSpeakers ? meeting.numberOfSpeakers : 1}{t('人')}
                      </td>
                      {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                        <td
                          className={`align-middle text-center ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}
                        >{meeting.additionalDocument && meeting.additionalDocument.length !== 0 ? (
                          <button
                            type="button"
                            className="btn btn-success m-0"
                            onClick={(e) => {e.stopPropagation(); handleShowAdditionalDocumentClick(meeting);}}
                          >{t('表示')}</button>
                        ) : (<></>)}</td>
                      )}
                      {(process.env.REACT_APP_ENVIRONMENT !== 'production' || UserInfo.isUnlimitedLPlan()) && (
                        <td
                          className={`align-middle text-center ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}
                        >{meeting.hasUploadedFiles ? (
                          <button
                            type="button"
                            className="btn btn-success m-0"
                            onClick={(e) => {e.stopPropagation(); handleFilesDownloadClick(meeting);}}
                          >{t('表示')}</button>
                        ) : (<></>)}</td>
                      )}
                      <td
                        className={`align-middle text-center ${selectedMeetingIds.has(meeting.id) ? 'bg-select' : ''}`}
                      >{meeting.beginAt && convertDateTimeToStringForView(meeting.beginAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : searching ? (
              <>{t('処理中…')}</>
            ) : (
              <>{t('ミーティングがありません。')}</>
            )
          ) : (
            <>{t('ミーティングがありません。')}</>
          )
        ) : (
          <>{t('処理中…')}</>
        )}
        <div ref={observerTarget} style={{ height: '20px' }} />
      </div>
      <hr />
      <section className="flex-shrink-0 d-flex gap-2 justify-content-between align-items-center bg-white py-2">
        <span className="text-muted">
          {t('選択中')}: {selectedMeetingIds.size} / {completedMeetings.length}
          {displayCount < completedMeetings.length &&
            ` (${displayCount} ${t('件')}${t('表示中')})`
          }
        </span>
        <button
          className="btn btn-primary rounded-pill"
          onClick={onMeetingIdsSelect}
        >
          {t('選択完了')}
        </button>
      </section>
    </div>
  );
});
