import { ADMIN_GET_USERS_USAGE } from '../constant/Endpoints'
import Api from './Api'

export class SummaryTask {
  taskId?: string
  data?: string
  intervalId?: NodeJS.Timer
  onCompleted?: (data: string) => void
  private endPoint: string // initial endpoint to start the task

  constructor(usageEndpoint: string = ADMIN_GET_USERS_USAGE) {
    this.endPoint = usageEndpoint
  }

  async start(onComplete?: (data: string) => void) {
    if (this.taskId) {
      // already started
      return
    }

    this.onCompleted = onComplete

    try {
      const res = await Api.get(this.endPoint)
      if (res.data.task) {
        this.taskId = res.data.task

        // start interval to check state
        this.intervalId = setInterval(async () => {
          await this.getState()
        }, 2000)
      } else {
        this.onCompleted?.('')
      }
    } catch (e) {
      console.warn('[Summary task] failed ', e)
      this.onCompleted?.('')
    }
  }

  async getState() {
    if (!this.taskId) {
      return
    }

    try {
      const res = await Api.get(`${ADMIN_GET_USERS_USAGE}/${this.taskId}`)
      console.log('Summary task data: ', res.data)

      // having data returned means the task is completed
      if (res.data.data) {
        this.data = res.data.data
        clearInterval(this.intervalId)
        this.intervalId = undefined

        this.onCompleted?.(this.data!)
        this.onCompleted = undefined
      }
    } catch (e) {
      // no need to stop the interval here, just keep checking until it's done
      console.warn('[Summary task] failed to fetch data', e)
    }
  }
}
