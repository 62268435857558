import './Advisor.css';

import { io } from 'socket.io-client';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import UserInfo from '../../store/UserInfo';
import Api from '../../api/Api';
import i18next from 'i18next';
import { ILanguages } from '../../constant/Languages';

interface Message {
  id: string;
  role: 'user' | 'assistant';
  content: string;
  error?: boolean;
}

type AssistantMessageSteamResponse = {
  finish: boolean;
  delta: string;
  snapshot: string;
  event: 'on_text_delta' | 'on_tool_call_created' | 'on_tool_call_done';
};

const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <span className="loading-dot"></span>
      <span className="loading-dot"></span>
      <span className="loading-dot"></span>
    </div>
  );
};

export const Advisor = observer(() => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isComposing, setIsComposing] = useState(false);
  const [socket, setSocket] = useState<any>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!UserInfo.id) return;
    if (!Api.defaults.headers.authorization) return;

    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (!baseUrl) {
      throw new Error("REACT_APP_BASE_URL is not defined");
    }

    // Initialize socket connection
    const newSocket = io(baseUrl.replace('/api', ''), {
      path: '/chat',
      auth: {
        userId: UserInfo.id,
        token: Api.defaults.headers.authorization
      },
      rejectUnauthorized: true,
      secure: true,
      transports: ['websocket'],
      reconnection: true,
    });
    setSocket(newSocket);

    // Add initial AI greeting
    setMessages([{
      id: '0',
      role: 'assistant',
      content: t('こんにちは! donutAIです。\n会議の内容などについて、お気軽に質問してくださいね。')
    }]);

    // Socket event listeners
    newSocket.on('ADVISOR_CHAT_RESPONSE', (id: string, chunk: AssistantMessageSteamResponse) => {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        const messageIndex = prevMessages.findIndex(msg => msg.id === id + '_response');
        if (messageIndex === -1) {
          newMessages.push({
            id: id + '_response',
            role: 'assistant',
            content: ''
          });
        } else {
          newMessages[messageIndex] = {
            ...newMessages[messageIndex],
            content: newMessages[messageIndex].content + chunk.delta
          };
        }
        return newMessages;
      });
    });

    newSocket.on('ADVISOR_CHAT_RESPONSE_ERROR', (id: string) => {
      setMessages(prevMessages => {
        const messageIndex = prevMessages.findIndex(msg => msg.id === id);
        const newMessages = prevMessages.filter(msg => msg.id !== id + '_response');
        if (messageIndex === -1) {
          newMessages.push({
            id: id,
            role: 'user',
            content: '',
            error: true
          });
        } else {
          newMessages[messageIndex] = {
            ...newMessages[messageIndex],
            error: true
          };
        }
        return newMessages;
      });
    });

    return () => {
      newSocket.disconnect();
    };
  }, [t]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = useCallback((content: string, clearInput: boolean = true) => {
    if (!content.trim()) return;

    const messageId = Date.now().toString();
    const newMessage: Message = {
      id: messageId,
      role: 'user',
      content: content
    };

    setMessages(prev => [...prev, newMessage, {
      id: messageId + '_response',
      role: 'assistant',
      content: ''
    }]);

    const lang = i18next.language;
    let code: ILanguages = ILanguages["ja-JP"];
    switch (lang) {
      case 'ja':
        code = ILanguages["ja-JP"];
        break;
      case 'en':
        code = ILanguages["en-US"];
        break;
      case 'id':
        code = ILanguages["id-ID"];
        break;
      default:
        code = ILanguages[lang as ILanguages];
        break;
    }
    socket.emit('ADVISOR_CHAT_REQUEST', messageId, content, code);
    if (clearInput) {
      setInputMessage('');
    }
  }, [socket]);

  const handleRetryMessage = useCallback((id: string) => {
    const message = messages.find(msg => msg.id === id);
    console.log('message', message)
    if (message) {
      sendMessage(message.content, false);
    }
  }, [messages, sendMessage]);

  const handleSendMessage = useCallback(() => {
    sendMessage(inputMessage);
  }, [inputMessage, sendMessage]);

  return (
    <div className='container p-4 p-md-4 text-donut'>
      <div className='row mb-3'>
        <div className='col-auto h1 text-primary mb-0'>
          <b>{t('AIアドバイザー')}</b>
        </div>
      </div>
      <div className="chat-messages p-4" style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>
        {messages.map((message) => (
          <div
            key={message.id}
            className={`d-flex ${message.role === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-3`}
          >
            {message.role === 'assistant' && (
              <div className="me-2">
                <img
                  src="/icon-donutAI.png"
                  alt="donut AI"
                  className="rounded-circle"
                  width={40}
                  height={40}
                />
              </div>
            )}
            <div
              className={`${message.role === 'user' ? 'bg-body-secondary text-black' : 'bg-light'} 
                    rounded p-3 max-w-75 chat-balloon border ${message.error ? 'border-danger bg-danger-subtle' : ''}`}
            >
              {message.role === 'assistant' && (
                <div className="small mb-1 fw-bold">donut AI</div>
              )}
              {message.role === 'assistant' && message.content === '' ? (
                <LoadingDots />
              ) : (
                <ReactMarkdown
                  className={message.role === 'assistant' ? 'markdown-body' : ''}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    a: ({ href, children }) => (
                      <a href={href} target="_blank" rel="noopener noreferrer">
                        {children}
                      </a>
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              )}
              {message.error && (
                <button
                  className="btn btn-sm btn-danger mt-2"
                  onClick={() => handleRetryMessage(message.id)}
                >
                  <i className="bi bi-arrow-repeat"></i> {t('再送信する')}
                </button>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-group mt-3">
        <input
          type="text"
          className="form-control"
          placeholder={t('donutAIに質問する...')}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isComposing) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <button
          className="btn btn-primary"
          onClick={handleSendMessage}
          disabled={!inputMessage.trim()}
        >
          <i className="bi bi-send-fill"></i>
        </button>
      </div>
    </div>
  );
});
