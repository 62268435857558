import queryString from 'query-string'

export const _parseQueryParam = (name: string, remove = true) => {
  const location = window.location
  const params = location.search

  let val = null
  if (params) {
    const parsed = queryString.parse(params)
    val = parsed[name]
    if (val && remove) {
      delete parsed[name]
      const newQuery = queryString.stringify(parsed)
      // remove param
      const newurl = location.protocol + '//' + location.host + location.pathname + (newQuery ? `?${newQuery}` : '')
      window.history.replaceState(window.history.state, '', newurl)
    }
  }

  return val
}

export const _padNumber = (num: number | string, charCount = 2) => {
  return `${num}`.padStart(charCount, '0')
}

export const _getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const CHARSET = [
  ...[...Array(26)].map((e, i) => String.fromCharCode('A'.charCodeAt(0) + i)),
  ...[...Array(26)].map((e, i) => String.fromCharCode('a'.charCodeAt(0) + i)),
  ...[...Array(10)].map((e, i) => String.fromCharCode('0'.charCodeAt(0) + i)),
]

export const _randomId = (chars = 12) => {
  let id = ''
  for (let i = 0; i < chars; i++) {
    id += CHARSET[_getRandomInt(0, CHARSET.length - 1)]
  }
  return id
}

// メールアドレス検証
export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

// コピー
export async function copyToClipboardWithRetryAndFallback(
  text: string,
  maxRetries: number = 3,
  delay: number = 1000
): Promise<void> {
  // リトライ処理
  for (let i = 0; i < maxRetries; i++) {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Text successfully copied to clipboard using clipboard API");
      return; // 成功したら関数終了
    } catch (error) {
      console.warn(`Clipboard write failed (attempt ${i + 1}):`, error);

      // 最終試行でも失敗した場合はフォールバックへ
      if (i + 1 === maxRetries) {
        console.warn("Switching to fallback clipboard method");
        fallbackCopyToClipboard(text);
        return;
      }

      // 次のリトライまで遅延
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
}

// フォールバック関数
function fallbackCopyToClipboard(text: string): void {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed"; // 画面のスクロールを防止
  textarea.style.opacity = "0"; // ユーザーから見えないようにする
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    document.execCommand("copy");
    console.log("Text successfully copied to clipboard using fallback method");
  } catch (error) {
    console.error("Fallback method failed to copy text:", error);
    alert("クリップボードへのコピーに失敗しました。手動でコピーしてください。");
  } finally {
    document.body.removeChild(textarea);
  }
}

// バイト数の単位を変換
export const formatFileSize = (bytes: number): string => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  if (bytes < 1024 * 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  return `${(bytes / (1024 * 1024 * 1024 * 1024)).toFixed(1)} TB`;
};
